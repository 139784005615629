import { NotificationsContext } from 'shared-components/contexts/NotificationsContext'
import { CommonDataContext } from 'shared-components/contexts/CommonDataContext'

export interface UseCopyProps {
  copy: (text: string) => Promise<void>
}

export function useCopy(): UseCopyProps {
  const { enqueue } = NotificationsContext.useContainer()
  const {
    common: { strings },
  } = CommonDataContext.useContainer()

  function fail(): void {
    enqueue({ msg: strings.errGeneral, variant: 'error', duration: 3000 })
  }

  return {
    async copy(text) {
      if ('clipboard' in navigator) {
        try {
          await navigator.clipboard.writeText(text)
          enqueue({ msg: 'Copied!', variant: 'success', duration: 3000 })
        } catch (e) {
          fail()
        }
      } else {
        fail()
      }
    },
  }
}
