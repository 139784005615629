import { useCallback, useEffect, useState } from 'react'

type StoredValue = string | null
export function useStored(key: string): [StoredValue, (val: StoredValue) => void] {
  const [stored, setStored] = useState<StoredValue>(null)

  useEffect(() => {
    setStored(localStorage.getItem(key))
  }, [key])

  return [
    stored,
    useCallback(
      newState => {
        setStored(newState)
        if (newState) {
          localStorage.setItem(key, newState)
        } else {
          localStorage.removeItem(key)
        }
      },
      [key]
    ),
  ]
}
