import { Fragment } from 'react'
import { htmlReactParse } from 'shared-code/html'

interface BreakedTextProps {
  children: string
}

const BreakedText: React.VFC<BreakedTextProps> = ({ children }) => (
  <>
    {children.split(/\n+/).map((item, index) => (
      <Fragment key={index}>
        {htmlReactParse(item)}
        <br />
      </Fragment>
    ))}
  </>
)

export default BreakedText
