import styles from './IconLabel.module.css'
import styleUtils from 'shared-components/styles/util.module.css'
import clsx from 'clsx'
import { BasicStyledComponent } from 'shared-definitions/types'
import type { ReactNode } from 'react'

export interface IconLabelProps extends BasicStyledComponent {
  title: string
  iconEl: ReactNode
}

const IconLabel: React.VFC<IconLabelProps> = ({ className, title, iconEl }) => (
  <div className={clsx(styles.container, className)}>
    {iconEl}
    <span className={styleUtils.cutoffText}>{title}</span>
  </div>
)

export default IconLabel
