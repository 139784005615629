import { createRef, useEffect, useRef, useState, type RefObject } from 'react'

type Key = number | string

interface UseMultiplyScrollHeightResult<T> {
  getRef: (key: Key) => RefObject<T>
  getHeight: (key: Key) => number | undefined
}

export function useMultiplyScrollHeight<T extends Element>(): UseMultiplyScrollHeightResult<T> {
  const refs = useRef<Record<Key, RefObject<T>>>({})
  const [heights, setHeights] = useState<Record<Key, number>>({})

  useEffect(() => {
    const observers: Record<Key, ResizeObserver> = {}
    for (const key in refs.current) {
      const ref = refs.current[key]
      if (!ref.current) {
        continue
      }

      const ob = new ResizeObserver(([entry]) => {
        setHeights(prev => {
          if (prev[key] === entry.target.scrollHeight) {
            return prev
          }
          return { ...prev, [key]: entry.target.scrollHeight }
        })
      })

      ob.observe(ref.current)

      if (key in observers) {
        observers[key].disconnect()
      }

      observers[key] = ob
    }

    return () => {
      for (const key in observers) {
        const observer = observers[key]
        observer.disconnect()
      }
    }
  }, [])

  return {
    getRef: key => {
      if (!(key in refs)) {
        refs.current[key] = createRef<T>()
      }

      return refs.current[key]
    },
    getHeight: key => heights[key],
  }
}
