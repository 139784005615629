import { DSNCBestProductGallery } from 'shared-definitions/types'
import styleUtils from 'shared-components/styles/util.module.css'
import DealsSimple from 'shared-components/ncui/DealsSimple'
import CartButtonLink from 'shared-components/buttons/CartButtonLink'
import stylesCommon from './NCBestProductTitle.NCBestProductGallery.module.css'
import SoundLabel from 'shared-components/ui/SoundLabel'
import BestComponentHeader from 'shared-components/ncui/BestComponentHeader'
import styles from './NCBestProductGallery.module.css'
import ImageSlider from 'shared-components/ui/ImageSlider'
import EditButton from 'shared-components/service/EditButton'

interface NCBestProductGalleryProps {
  data: DSNCBestProductGallery
}

const NCBestProductGallery: React.VFC<NCBestProductGalleryProps> = ({ data }) => (
  <div className={styleUtils.nc}>
    <EditButton pLink={data.pLinkEdit} elevated pLinkRequestUpdate={null} />
    <BestComponentHeader
      title={data.title}
      score={data.score}
      label={data.headLabel}
      className={styles.header}
    />
    <ImageSlider images={data.images} className={styles.gallery}>
      {data.headLabel ? <SoundLabel className={styles.label} label={data.headLabel} /> : null}
    </ImageSlider>
    <DealsSimple textBadge={null} title={data.title} moreLink={data.reviewLink} msrp={data.msrp}>
      {data.productLink ? (
        <CartButtonLink {...data.productLink} className={stylesCommon.button}>
          {'Buy now'}
        </CartButtonLink>
      ) : null}
    </DealsSimple>
  </div>
)

export default NCBestProductGallery
