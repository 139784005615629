import clsx from 'clsx'
import CreditLink from 'shared-components/ui/CreditLink'
import { BasicStyledComponent, DSCredit } from 'shared-definitions/types'
import styles from './CreditsLine.module.css'

interface CreditsLineProps extends BasicStyledComponent {
  credits: readonly DSCredit[]
}

const CreditsLine: React.FC<CreditsLineProps & React.PropsWithChildren> = ({
  children,
  className,
  credits,
}) => (
  <div className={clsx(styles.container, className)}>
    {credits.map((item, index) => (
      <CreditLink credit={item} key={index} />
    ))}
    {children}
  </div>
)

export default CreditsLine
