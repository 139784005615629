import { DSNCProductsQuiz } from 'shared-definitions/types'
import styles from './NCProductsQuiz.module.css'
import styleUtils from 'shared-components/styles/util.module.css'
import clsx from 'clsx'
import { ThemeContext } from '../contexts/ThemeContext'
import { useQuizIframe } from '../hooks/use-quiz-iframe'
import { useEffect } from 'react'

interface NCProductsQuizProps {
  data: DSNCProductsQuiz
}

const NCProductsQuiz: React.FC<NCProductsQuizProps> = ({ data }) => {
  const { ready, theme } = ThemeContext.useContainer()
  const { refIframe } = useQuizIframe({ ipGetterApi: data.ipGetterApi })

  useEffect(() => {
    if (!refIframe.current) {
      return
    }
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    }

    const observerCallback: IntersectionObserverCallback = entries => {
      entries.forEach(entry => {
        refIframe.current?.contentWindow?.postMessage(
          { action: 'inViewport', inViewport: entry.isIntersecting },
          '*'
        )
      })
    }

    const observer = new IntersectionObserver(observerCallback, observerOptions)
    observer.observe(refIframe.current)
  }, [refIframe.current])

  if (!ready) {
    return <div className={clsx(styleUtils.nc, styles.container)} />
  }

  return (
    <iframe
      onLoad={e => {
        console.log('iframe', 'Set ready sent')
        e.currentTarget.contentWindow?.postMessage('iframeReady', '*')
      }}
      ref={refIframe}
      loading="lazy"
      className={clsx(styleUtils.nc, styles.container)}
      src={`${data.iframeUrl}/?theme=${theme}`}
      allowFullScreen
      scrolling="no"
      title={data.title}
    />
  )
}

export default NCProductsQuiz
