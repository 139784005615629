/* eslint-disable react/jsx-no-bind */
import { DSNCPre } from 'shared-definitions/types'
import clsx from 'clsx'
import styles from './NCPre.module.css'
import styleUtils from 'shared-components/styles/util.module.css'
import { htmlReactParse } from 'shared-code/html'
import BasicButton from 'shared-components/buttons/BasicButton'
import { useCopy } from 'shared-components/hooks/use-copy'
import { useRef } from 'react'

interface NCPreProps {
  data: DSNCPre
}

const NCPre: React.VFC<NCPreProps> = ({ data }) => {
  const ref = useRef<HTMLElement>(null)
  const { copy } = useCopy()

  const copyCb = (): void => {
    const { current } = ref
    if (current) {
      void copy(current.innerHTML)
    }
  }

  return (
    <div className={clsx(styles.container, styleUtils.nc)}>
      <div className={styles.lang}>{'Code'}</div>
      <BasicButton onClick={copyCb} className={styles.button}>
        {'Copy Text'}
      </BasicButton>
      <pre className={clsx(styles.pre, styleUtils.thinScrollbar)}>
        <code ref={ref} className={styles.code}>
          {htmlReactParse(data.bodyHtml)}
        </code>
      </pre>
    </div>
  )
}

export default NCPre
