import styles from './FeedbackWidget.module.css'
import ThumbUp from 'shared-svg/ThumbUp.svg'
import clsx from 'clsx'
import { BasicStyledComponent } from 'shared-definitions/types'

interface FeedbackWidgetProps extends BasicStyledComponent {
  question: string
  onPositive: () => void
  onNegative: () => void
}

const FeedbackWidget: React.VFC<FeedbackWidgetProps> = ({
  question,
  className,
  onNegative,
  onPositive,
}) => (
  <div className={clsx(styles.container, className)}>
    <div className={styles.question}>{question}</div>
    <button
      type="button"
      className={clsx(styles.button, styles.buttonPositive)}
      onClick={onPositive}
    >
      <ThumbUp className={clsx(styles.icon, styles.iconPositive)} />
    </button>
    <button
      type="button"
      className={clsx(styles.button, styles.buttonNegative)}
      onClick={onNegative}
    >
      <ThumbUp className={clsx(styles.icon, styles.iconNegative)} />
    </button>
  </div>
)

export default FeedbackWidget
