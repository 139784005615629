export function paragraphSplitter(html: string): string[] {
  const paragraphs = html.split(/<\/p>\s*<p>/)
  if (paragraphs.length > 1) {
    const first = `${paragraphs[0]}</p>`
    const last = `<p>${paragraphs[paragraphs.length - 1]}`

    paragraphs[0] = first
    paragraphs[paragraphs.length - 1] = last
    for (let i = 1; i < paragraphs.length - 1; i++) {
      paragraphs[i] = `<p>${paragraphs[i]}</p>`
    }
  }
  return paragraphs
}

export function renderString(tpl: string, vars: Record<string, string | number>): string {
  return Object.keys(vars).reduce((carry, key) => {
    if (vars) {
      return carry.replace(`[${key}]`, vars[key].toString())
    }
    return carry
  }, tpl)
}

type Replacer = string | ((substring: string, ...args: string[]) => string)

export function regexpConveyor(input: string, phases: readonly [RegExp, Replacer][]): string {
  let result = input
  for (const phase of phases) {
    result = result.replace(phase[0], phase[1] as string)
    if (result === '') {
      break
    }
  }
  return result
}

export function youtubeVideoId(youtubeUrl: string): string | null {
  const parsers: Array<[RegExp, (executed: string[]) => string]> = [
    [/youtu.be\/([^?]*)/i, executed => executed[1]],
    [/v=([^&#]+)/i, executed => executed[1]],
    [/embed\/([^?#]+)/i, executed => executed[1]],
  ]

  for (const parser of parsers) {
    const executed = parser[0].exec(youtubeUrl)
    if (executed) {
      return parser[1](executed)
    }
  }

  return null
}

// function normalizeParagraph(input: string): string {}

// prevent number based ids (querySelector throws errors on such ids)
export function normalizeId(input: string): string {
  return `id-${input}`
}

export function cutLinks(html: string): string {
  return html.replace(/<a[^>]*>([^<]*)<\/a>/g, '<strong>$1</strong>')
}
