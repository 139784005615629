import styles from './IconedButtonLink.module.css'
import clsx from 'clsx'
import { BasicStyledComponent } from 'shared-definitions/types'
import { LinkedProps } from 'shared-components/service/Linked'
import BasicButtonLink from './BasicButtonLink'
import { ComponentType } from 'react'

interface IconedButtonLinkProps extends BasicStyledComponent, LinkedProps {
  icon: ComponentType<BasicStyledComponent>
}

const IconedButtonLink: React.FC<IconedButtonLinkProps & React.PropsWithChildren> = ({
  className,
  children,
  icon: Icon,
  ...props
}) => (
  <BasicButtonLink className={clsx(className, styles.container)} {...props}>
    {children}
    <Icon className={styles.icon} />
  </BasicButtonLink>
)

export default IconedButtonLink
