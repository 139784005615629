import styles from './ImageBlock.module.css'
import AppImage from 'shared-components/ui/AppImage'
import CreditsLine from 'shared-components/ui/CreditsLine'
import CaptionLine from 'shared-components/ui/CaptionLine'
import clsx from 'clsx'
import { BasicStyledComponent, DSImageProps } from 'shared-definitions/types'
import { BreakPointSize } from 'shared-code/responsive'
import { cutLinks } from 'shared-code/string'

interface ImageBlockProps extends BasicStyledComponent {
  image: DSImageProps
  preload?: boolean
  sizes: BreakPointSize[] | BreakPointSize
  badges: readonly DSImageProps[]
  cutLinks?: boolean
}

const ImageBlock: React.VFC<ImageBlockProps> = ({
  cutLinks: cutImageLinks,
  badges,
  sizes,
  className,
  image,
  preload,
}) => (
  <div
    className={clsx(styles.subContainer, className)}
    style={image.size ? { maxWidth: `${image.size.w}px` } : undefined}
  >
    <AppImage layout="responsive" image={image} preload={preload} sizes={sizes} />
    <CreditsLine credits={image.credits} className={styles.credits}>
      {badges.map((item, index) => (
        <AppImage
          imageClassName={styles.badge}
          className={styles.badgeContainer}
          image={item}
          key={index}
          layout="raw"
          sizes={[{ bp: 'sm', val: 80 }, 60]}
        />
      ))}
    </CreditsLine>
    {image.caption ? (
      <CaptionLine>{cutImageLinks ? cutLinks(image.caption) : image.caption}</CaptionLine>
    ) : null}
  </div>
)

export default ImageBlock
