/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/jsx-no-literals */

import { NCAudioProps } from 'shared-definitions/types'
import { useAudioChart } from 'shared-components/hooks/use-audio-chart'
import { useEffect } from 'react'
import styles from './NCAudioChart.module.css'
import clsx from 'clsx'
import ArrowBottomBird from 'shared-svg/ArrowBottomBird.svg'

const NCAudioChart: React.VFC<NCAudioProps> = ({ data: { chart } }) => {
  const {
    filteredModels,
    isLoading,
    chartUrl,
    scrollPosition,
    dropdownOpen,
    filteredModelMeasures,
    filterValue,
    selectedModel,
    selectedModelMeasure,
    dropdownRef,
    dropdownFilterRef,
    vileModelMeasures,
    ref,
    filterModels,
    handleComparisonChange,
    handleModelMeasureChange,
    updateLoadingState,
    resetFilter,
    updateDropdownVisibility,
  } = useAudioChart(chart)

  useEffect(() => {
    if (scrollPosition) {
      window.scrollTo(0, scrollPosition)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return (
    <div ref={ref} className={styles.container}>
      {isLoading && (
        <div className={styles.placeholder}>
          <p>{'Loading chart ...'}</p>
        </div>
      )}

      <object
        aria-label="Audio Chart"
        data={chartUrl}
        type="text/html"
        onLoad={() => {
          if (chartUrl) {
            updateLoadingState(false)
          }
        }}
        width="100%"
        height={isLoading ? '0' : '470'}
      />
      {selectedModel?.id && (
        <div className={styles.selectContainer}>
          <span className={styles.selectLabel}>{'Comparison model'}</span>

          <div
            ref={dropdownRef}
            className={clsx(styles.dropdown, {
              [styles.dropdownOpen]: dropdownOpen && selectedModel?.id,
            })}
          >
            <div
              onClick={() => {
                updateDropdownVisibility(!dropdownOpen)
              }}
              role="button"
              tabIndex={0}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  updateDropdownVisibility(!dropdownOpen)
                }
              }}
              className={styles.currentSelectionContainer}
            >
              <span className={styles.currentSelection}>{selectedModel?.name}</span>
              <ArrowBottomBird className={clsx(styles.arrowIcon)} />
            </div>

            <div className={styles.dropdownContent}>
              <div className={styles.filterContainer}>
                <input
                  value={filterValue}
                  onChange={filterModels}
                  name="Filter"
                  ref={dropdownFilterRef}
                  className={styles.dropdownFilter}
                  type="text"
                  placeholder="Filter by name"
                />
                <button
                  type="button"
                  className={styles.resetFilter}
                  onClick={() => {
                    resetFilter()
                  }}
                >
                  &times;
                </button>
              </div>

              <ul className={styles.dropdownOptionsContainer}>
                {filteredModels &&
                  filteredModels.map((singleModel, index) => (
                    <li className={clsx(styles.dropdownOption)} key={index}>
                      <button
                        className={clsx(styles.dropdownOptionActionButton, {
                          [styles.selectedOption]: selectedModel?.id === singleModel.id,
                        })}
                        onClick={() => {
                          void handleComparisonChange(singleModel)
                        }}
                        type="button"
                      >
                        {selectedModel?.id === singleModel.id && (
                          <span className={styles.selectedCheckMark}>&#x2713;</span>
                        )}

                        {singleModel.product.name}
                      </button>
                    </li>
                  ))}

                {filteredModels && filteredModels.length < 1 && (
                  <li className={styles.emptyDropdownOption}>No matching model</li>
                )}
              </ul>
            </div>
          </div>
        </div>
      )}
      {selectedModelMeasure?.id && (
        <div className={styles.selectContainer}>
          <span className={styles.selectLabel}>{'Listening mode'}</span>

          <div
            ref={dropdownRef}
            className={clsx(styles.dropdown, {
              [styles.dropdownOpen]: dropdownOpen && selectedModelMeasure?.id,
            })}
          >
            <div
              onClick={() => {
                updateDropdownVisibility(!dropdownOpen)
              }}
              role="button"
              tabIndex={0}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  updateDropdownVisibility(!dropdownOpen)
                }
              }}
              className={styles.currentSelectionContainer}
            >
              <span className={styles.currentSelection}>{selectedModelMeasure?.name}</span>
              <ArrowBottomBird className={clsx(styles.arrowIcon)} />
            </div>

            <div className={styles.dropdownContent}>
              <div className={styles.filterContainer}>
                <input
                  value={filterValue}
                  onChange={filterModels}
                  name="Filter"
                  ref={dropdownFilterRef}
                  className={styles.dropdownFilter}
                  type="text"
                  placeholder="Filter by name"
                />
                <button
                  type="button"
                  className={styles.resetFilter}
                  onClick={() => {
                    resetFilter()
                  }}
                >
                  &times;
                </button>
              </div>

              <ul className={styles.dropdownOptionsContainer}>
                {vileModelMeasures &&
                  filteredModelMeasures.map((singleModelMeasure, index) => (
                    <li className={clsx(styles.dropdownOption)} key={index}>
                      <button
                        className={clsx(styles.dropdownOptionActionButton, {
                          [styles.selectedOption]:
                            selectedModelMeasure?.id === singleModelMeasure.id,
                        })}
                        onClick={() => {
                          void handleModelMeasureChange(singleModelMeasure)
                        }}
                        type="button"
                      >
                        {selectedModelMeasure?.id === singleModelMeasure.id && (
                          <span className={styles.selectedCheckMark}>&#x2713;</span>
                        )}

                        {singleModelMeasure.name}
                      </button>
                    </li>
                  ))}

                {filteredModelMeasures && filteredModelMeasures.length < 1 && (
                  <li className={styles.emptyDropdownOption}>No matching measure</li>
                )}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default NCAudioChart
