import clsx from 'clsx'
import { useEffect, useState } from 'react'
import styleUtils from 'shared-components/styles/util.module.css'
import { DSNCDealsLatest } from 'shared-definitions/types'
import ArrowRightShade from 'shared-svg/ArrowRightShade.svg'
import Linked from '../service/Linked'
import DealsLatestCard from '../ui/DealsLatestCard'
import StackAccordion from '../ui/StackAccordion'
import styles from './NCDealsLatest.module.css'
import { AppEventsContext } from '../contexts/AppEventsContext'

interface NCDealsLatestProps {
  data: DSNCDealsLatest
}

const NCDealsLatest: React.FC<NCDealsLatestProps> = ({ data }) => {
  // to avoid CLS shift we wait for the accordion to be rendered
  const [loaded, setLoaded] = useState(false)
  const { appEvent } = AppEventsContext.useContainer()

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 500)
  }, [])

  return (
    <div className={clsx(styleUtils.nc, styles.container)}>
      <h4 className={styles.title}>{data.title}</h4>
      <Linked {...data.button.pLink} className={styles.button}>
        {data.button.label}
        <ArrowRightShade className={styles.buttonIcon} />
      </Linked>
      <div
        className={clsx(styles.cards, styleUtils.mobileFull, {
          [styles.accordionPending]: !loaded,
        })}
      >
        <StackAccordion
          items={data.items}
          render={item => (
            <DealsLatestCard
              onClick={() => {
                appEvent({
                  event: 'widget-interaction',
                  widgetType: 'Product Widget',
                  label: item.priceButton?.link.label ?? '',
                  url: item.priceButton?.link.pLink.href ?? '',
                })
              }}
              {...item}
            />
          )}
        />
      </div>
    </div>
  )
}

export default NCDealsLatest
