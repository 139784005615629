import styles from './SplitterLine.module.css'
import clsx from 'clsx'
import { BasicStyledComponent } from 'shared-definitions/types'

interface SplitterLineProps extends BasicStyledComponent {
  variant?: 'bright' | 'slight'
}

const SplitterLine: React.VFC<SplitterLineProps> = ({ variant, className }) => (
  <div
    className={clsx(styles.container, className, {
      [styles.bright]: variant === 'bright',
      [styles.slight]: variant === 'slight' || !variant,
    })}
  />
)

export default SplitterLine
