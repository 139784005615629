import clsx from 'clsx'
import { BasicStyledComponent } from 'shared-definitions/types'
import styles from './IconedButton.module.css'

interface IconedButtonProps extends BasicStyledComponent {
  onClick: () => void
}

const IconedButton: React.FC<IconedButtonProps & React.PropsWithChildren> = ({
  className,
  children,
  onClick,
}) => (
  <button onClick={onClick} type="button" className={clsx(styles.button, className)}>
    {children}
  </button>
)

export default IconedButton
