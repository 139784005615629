/* eslint-disable react/jsx-no-bind */
import { DSNCGallery } from 'shared-definitions/types'
import clsx from 'clsx'
import styleUtils from 'shared-components/styles/util.module.css'
import styles from './NCGallery.module.css'
import AppImage from 'shared-components/ui/AppImage'
import dynamic from 'next/dynamic'
import { PopupContext } from 'shared-components/contexts/PopupContext'
import { CSSProperties } from 'react'
import { htmlReactParse } from 'shared-code/html'
import viewport from 'shared-definitions/viewport.json'

const ImageGallery = dynamic(() => import('shared-components/ui/ImageGallery'))

interface NCGalleryProps {
  data: DSNCGallery
}

const NCGallery: React.VFC<NCGalleryProps> = ({ data }) => {
  const { setPopupContent, setPopupOpened } = PopupContext.useContainer()
  return (
    <div
      className={clsx(styles.container, styleUtils.nc)}
      style={
        {
          '--columns': data.cols,
        } as CSSProperties
      }
    >
      {data.items.map((item, index) => (
        <button
          type="button"
          key={index}
          onClick={() => {
            setPopupContent(
              <ImageGallery
                onClose={() => setPopupOpened(false)}
                defaultIndex={index}
                images={data.items}
              />,
              styles.popup
            )
            setPopupOpened(true)
          }}
        >
          <AppImage
            image={item}
            layout="responsive"
            sizes={[`calc(min(calc(100vw - rem(30)), rem(${viewport['lg']})) / ${data.cols})`]}
          />
          {item.caption ? (
            <span className={styles.caption}>{htmlReactParse(item.caption)}</span>
          ) : null}
        </button>
      ))}
    </div>
  )
}

export default NCGallery
