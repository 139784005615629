import styles from './GalleryNavigateButton.module.css'
import clsx from 'clsx'
import { BasicStyledComponent } from 'shared-definitions/types'
import { ComponentType } from 'react'

interface GalleryNavigateButtonProps extends BasicStyledComponent {
  id?: string
  icon: ComponentType<BasicStyledComponent>
  onClick?: () => void
}

const GalleryNavigateButton: React.VFC<GalleryNavigateButtonProps> = ({
  id,
  className,
  onClick,
  icon: Icon,
}) => (
  <button id={id} className={clsx(styles.container, className)} type="button" onClick={onClick}>
    <Icon className={styles.icon} />
  </button>
)

export default GalleryNavigateButton
