import { ComponentType } from 'react'
import { ResponsiveContext } from 'shared-components/contexts/ResponsiveContext'
import {
  DSNCMulti,
  DSNCSponsored,
  DSResource,
  DSTpl,
  NCComponentInterface,
} from 'shared-definitions/types'

interface NCMultiProps<T extends DSResource> {
  data: DSNCMulti<T> | DSNCSponsored<T>
  tpl: DSTpl
  nc: ComponentType<NCComponentInterface<T>>
}

const NCMulti = <T extends DSResource>({
  data,
  tpl,
  nc: NC,
}: NCMultiProps<T>): React.ReactElement | null => {
  const { ready, lg } = ResponsiveContext.useContainer()
  if ('visible' in data) {
    if (!ready) {
      return null
    }

    if (data.visible === 'mobile' && lg) {
      return null
    }

    if (data.visible === 'desktop' && !lg) {
      return null
    }
  }

  return (
    <>
      {data.blocks.map((item, index) => (
        <NC index={index} key={index} data={item} tpl={tpl} />
      ))}
    </>
  )
}

export default NCMulti
