import clsx from 'clsx'
import { ComponentType } from 'react'
import styleUtils from 'shared-components/styles/util.module.css'
import { DSNCColumns, DSResource, DSTpl, NCComponentInterface } from 'shared-definitions/types'
import styles from './NCColumns.module.css'

interface NCColumnsProps<T extends DSResource> {
  data: DSNCColumns<T>
  tpl: DSTpl
  index: number
  nc: ComponentType<NCComponentInterface<T>>
}

const NCColumns = <T extends DSResource>({
  data,
  index,
  nc: NC,
}: NCColumnsProps<T>): React.ReactElement => (
  <div
    style={{
      gridTemplateColumns: `repeat(${data.perRow}, minmax(0, 1fr))`,
    }}
    className={clsx(styles.container, styleUtils.nc)}
  >
    {data.columns.map((colItem, colIndex) => (
      <div style={{ gridColumn: `span ${colItem.span}` }} key={colIndex}>
        {colItem.items.map((item, itemIndex) => (
          <NC index={index} key={itemIndex} data={item} tpl="in-column" />
        ))}
      </div>
    ))}
  </div>
)

export default NCColumns
