import { DSNCOpinion } from 'shared-definitions/types'
import Info from 'shared-svg/Info.svg'
import clsx from 'clsx'
import styleUtils from 'shared-components/styles/util.module.css'
import styles from './NCOpinion.module.css'
import Linked from 'shared-components/service/Linked'
import AppImage from 'shared-components/ui/AppImage'
import { NotificationsContext } from 'shared-components/contexts/NotificationsContext'

interface NCOpinionProps {
  data: DSNCOpinion
}

const NCOpinion: React.VFC<NCOpinionProps> = ({ data }) => {
  const { enqueue } = NotificationsContext.useContainer()

  return (
    <div className={clsx(styles.container, styleUtils.nc)}>
      <Linked className={styles.link} {...data.pLink}>
        {data.image ? (
          <AppImage layout="raw" sizes={40} className={styles.imageContainer} image={data.image} />
        ) : null}
        <div className={styles.author}>{data.authorName}</div>
        <div className={styles.text}>{data.text}</div>
      </Linked>
      <button
        type="button"
        onClick={() =>
          enqueue({
            duration: 5000,
            msg: data.hint,
            variant: 'info',
          })
        }
      >
        <Info className={styles.buttonIcon} />
      </button>
    </div>
  )
}

export default NCOpinion
