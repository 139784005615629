import { BasicStyledComponent } from 'shared-definitions/types'
import styleUtils from 'shared-components/styles/util.module.css'
import { CommonDataContext } from 'shared-components/contexts/CommonDataContext'
import styles from './Disclaimer.module.css'
import { htmlReactParse } from 'shared-code/html'
import clsx from 'clsx'

type DisclaimerProps = BasicStyledComponent

const Disclaimer: React.VFC<DisclaimerProps> = () => {
  const {
    common: { strings },
  } = CommonDataContext.useContainer()
  return (
    <div className={clsx(styles.container, styleUtils.wrapper)}>
      {htmlReactParse(strings.disclaimer)}
    </div>
  )
}

export default Disclaimer
