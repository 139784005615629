import { useState } from 'react'
import { createContainer } from 'shared-components/unstated'

export interface CBAdsControlValueProps {
  voidQuantity: number
}

interface CBAdsControlProps extends CBAdsControlValueProps {
  enumerateVoidAds: (n?: number) => void
}

export const CBAdsControlContext = createContainer<CBAdsControlProps, CBAdsControlValueProps>(
  (initial = { voidQuantity: 0 }) => {
    const [voidQuantity, setVoidQuantity] = useState(initial.voidQuantity)

    function enumerateVoidAds(n = 1): void {
      setVoidQuantity(prev => (prev += n))
    }

    return {
      voidQuantity,
      enumerateVoidAds,
    }
  }
)
