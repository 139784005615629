/* eslint-disable react/display-name */
import clsx from 'clsx'
import { CSSProperties, forwardRef, useCallback } from 'react'
import { useInView } from 'react-intersection-observer'
import { BasicStyledComponent } from 'shared-definitions/types'
import styles from './LazyLoad.module.css'

interface LazyLoadResponsive {
  layout: 'responsive'
  ratio: { w: number; h: number }
}

interface LazyLoadRaw {
  layout: 'raw'
}

interface BaseProps extends BasicStyledComponent {
  children: () => React.ReactNode
  style?: CSSProperties
  onFocus?: () => void
  onBlur?: () => void
}

export type LazyLoadComposed = LazyLoadRaw | LazyLoadResponsive

const LazyLoad = forwardRef<HTMLDivElement, LazyLoadComposed & BaseProps>((props, fRef) => {
  const { ref, inView } = useInView({ triggerOnce: true })

  const style: CSSProperties = props.style ?? {}
  if (props.layout === 'responsive') {
    style.paddingTop = `${((props.ratio.h / props.ratio.w) * 100).toFixed(2)}%`
    style.aspectRatio = `${props.ratio.w} / ${props.ratio.h}`
  }

  const setRefs = useCallback(
    (node: HTMLDivElement) => {
      if (fRef && typeof fRef !== 'function') {
        fRef.current = node
      }
      ref(node)
    },
    [ref]
  )

  return (
    <div
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      ref={setRefs}
      style={style}
      className={clsx(props.className, styles.container)}
    >
      {inView ? props.children() : null}
    </div>
  )
})

export default LazyLoad
