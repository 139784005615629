/* eslint-disable react/jsx-no-bind */
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import { ComponentType, CSSProperties, ReactElement, useState } from 'react'
import { PopupContext } from 'shared-components/contexts/PopupContext'
import { useScrollHeight } from 'shared-components/hooks/use-scroll-height'
import styleUtils from 'shared-components/styles/util.module.css'
import AppImage from 'shared-components/ui/AppImage'
import { DSNCFaq, DSResource, DSTpl, NCComponentInterface } from 'shared-definitions/types'
import ArrowBottomBird from 'shared-svg/ArrowBottomBird.svg'
import CommentQuestion from 'shared-svg/CommentQuestion.svg'
import styles from './NCFaq.module.css'
import { AppEventsContext } from '../contexts/AppEventsContext'
import { useShortcodeAnalytics } from '../hooks/use-shortcode-analytics'
import FeedbackWidget from '../ui/FeedbackWidget'
import { NotificationsContext } from '../contexts/NotificationsContext'
import { CommonDataContext } from '../contexts/CommonDataContext'

const ImageBlockPopup = dynamic(() => import('shared-components/ui/ImageBlockPopup'))

interface NCFaqProps<T extends DSResource> {
  data: DSNCFaq<T>
  tpl: DSTpl
  nc: ComponentType<NCComponentInterface<T>>
}

const NCFaq = <T extends DSResource>({ data, nc: NC, tpl }: NCFaqProps<T>): ReactElement => {
  const { setPopupOpened, setPopupContent } = PopupContext.useContainer()
  const { ref, height } = useScrollHeight<HTMLDivElement>()
  const [opened, setOpened] = useState(false)
  const { appEvent } = AppEventsContext.useContainer()

  const {
    common: { strings },
  } = CommonDataContext.useContainer()
  const { trackEvent } = useShortcodeAnalytics(data.analytics)
  const [feedbackVisible, setFeedbackVisible] = useState(true)
  const { enqueue } = NotificationsContext.useContainer()

  function zoomIn(): void {
    if (!data.image) {
      return
    }
    setPopupContent(
      <ImageBlockPopup
        className={styles.popupBlock}
        image={data.image}
        onClose={() => setPopupOpened(false)}
      />,
      styles.popupContainer
    )
    setPopupOpened(true)
  }

  return (
    <div className={clsx(styleUtils.nc, styles.container, { [styles.opened]: opened })}>
      <button
        type="button"
        className={styles.openButton}
        onClick={() => {
          setOpened(s => !s)
          appEvent({
            event: 'widget-interaction',
            widgetType: 'FAQ',
            label: data.question,
            url: window.location.href,
          })
          if (!opened) {
            void trackEvent({ type: 'expand' })
          } else {
            void trackEvent({ type: 'collapse' })
          }
        }}
      >
        {data.icon === 'ask-question' ? (
          <CommentQuestion className={styles.openButtonLeftIcon} />
        ) : null}
        <span className={styles.buttonTitle}>{data.question}</span>
        <ArrowBottomBird className={styles.openButtonIcon} />
      </button>
      <div
        className={styles.body}
        ref={ref}
        style={{ '--scroll-height': `${height}px` } as CSSProperties}
      >
        {data.image ? (
          <button type="button" className={styles.imageButton} onClick={zoomIn}>
            <AppImage
              image={data.image}
              layout="responsive"
              sizes={[{ bp: 'sm', val: 130 }, 'calc(100vw - rem(30))']}
            />
          </button>
        ) : null}
        <div>
          {data.blocks.map((item, index) => (
            <NC key={index} index={index} data={item} tpl={tpl} />
          ))}
          {data.evaluateBlock && feedbackVisible && (
            <FeedbackWidget
              className={styles.feedbackWidget}
              question={data.evaluateBlock.title}
              onNegative={() => {
                void trackEvent({ type: 'thumbs_down' })
                enqueue({ msg: strings.msgSubmit, duration: 3000, variant: 'success' })
                setFeedbackVisible(false)
              }}
              onPositive={() => {
                void trackEvent({ type: 'thumbs_up' })
                enqueue({ msg: strings.msgSubmit, duration: 3000, variant: 'success' })
                setFeedbackVisible(false)
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default NCFaq
