import styleUtils from 'shared-components/styles/util.module.css'
import ImageBlock from 'shared-components/ui/ImageBlock'
import { DSNCImage, DSTpl } from 'shared-definitions/types'
import viewport from 'shared-definitions/viewport.json'

interface NCImgProps {
  data: DSNCImage
  index: number
  tpl: DSTpl
}

const NCImg: React.VFC<NCImgProps> = ({ data: { badges, image }, index }) => (
  <div className={styleUtils.mobileFull}>
    <ImageBlock
      badges={badges}
      image={image}
      preload={index === 0}
      sizes={[{ bp: 'lg', val: viewport['lg'] }, '80vw']}
    />
  </div>
)

export default NCImg
