import clsx from 'clsx'
import { CSSProperties, useState } from 'react'
import { BasicStyledComponent } from 'shared-definitions/types'
import Arrow from 'shared-svg/ArrowBottomBird.svg'
import { useMultiplyScrollHeight } from '../hooks/use-multiply-scroll-height'
import styles from './StackAccordion.module.css'

interface StackAccordionProps<T> {
  items: T[]
  render: (item: T, index: number) => React.ReactElement
}

// eslint-disable-next-line space-before-function-paren,react/function-component-definition
const StackAccordion = function <T>({
  items,
  render,
  className,
}: StackAccordionProps<T> & BasicStyledComponent): React.ReactElement {
  const [expanded, setExpanded] = useState<boolean>(false)
  const { getHeight, getRef } = useMultiplyScrollHeight()

  let sHeight = 0
  return (
    <ul
      ref={getRef('container') as React.RefObject<HTMLUListElement>}
      className={clsx(styles.container, className, {
        [styles.expanded]: expanded,
      })}
      style={
        {
          '--first-height': `${getHeight(0) ?? 0}px`,
          '--container-height': `${getHeight('container') ?? 0}px`,
        } as CSSProperties
      }
    >
      {items.map((item, index) => {
        sHeight += getHeight(index) ?? 0
        const result = (
          <li
            ref={getRef(index) as React.RefObject<HTMLLIElement>}
            className={clsx(styles.card, {
              [styles.disabledCard]: !expanded && index,
              [styles.fadedOutCard]: !expanded && index >= 3,
            })}
            key={index}
            style={
              {
                zIndex: items.length - index,
                '--index': index,
                // '--height': `${getHeight(index) ?? 0}px`,
                '--top-shift': `${sHeight}px`,
              } as CSSProperties
            }
          >
            {render(item, index)}
            {index === 0 && items.length > 1 && (
              <button
                className={clsx(styles.button)}
                type="button"
                onClick={() => setExpanded(s => !s)}
              >
                <Arrow
                  className={clsx(styles.buttonIcon, {
                    [styles.expandedButtonIcon]: expanded,
                  })}
                />
              </button>
            )}
          </li>
        )

        return result
      })}
    </ul>
  )
}

export default StackAccordion
