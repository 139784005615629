import { useRef, RefObject, useEffect, useState } from 'react'
import { TooltipContext } from 'shared-components/contexts/TooltipContext'

export function useTooltipsContainer<T extends HTMLElement>(): RefObject<T> {
  const ref = useRef<T>(null)
  const { setActive, setPos } = TooltipContext.useContainer()
  const [hasHover, setHasHover] = useState(false)

  useEffect(() => {
    setHasHover(window.matchMedia('(hover: hover)').matches)
  }, [])

  useEffect(() => {
    const { current } = ref
    if (!current) {
      return
    }

    const handler = (e: MouseEvent): void => {
      const { target } = e
      if (!(target instanceof HTMLAnchorElement)) {
        return
      }

      const tooltipId = target.getAttribute('data-tooltip-id')
      if (!tooltipId) {
        return
      }

      const rect = target.getBoundingClientRect()
      setActive(tooltipId)
      setPos({
        left: rect.x + rect.width / 2,
        top: window.scrollY + rect.y + rect.height,
      })
      e.preventDefault()
    }

    const event = hasHover ? 'mouseover' : 'click'
    current.addEventListener(event, handler)
    return () => {
      current.removeEventListener(event, handler)
    }
  }, [setActive, setPos, hasHover])

  return ref
}
