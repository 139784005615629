import styles from './CaptionLine.module.css'
import clsx from 'clsx'
import { BasicStyledComponent } from 'shared-definitions/types'
import { htmlReactParse } from 'shared-code/html'
import styleContent from 'shared-components/styles/content.module.css'

interface CaptionLineProps extends BasicStyledComponent {
  children: string
}

const CaptionLine: React.VFC<CaptionLineProps> = ({ className, children }) => (
  <div className={clsx(styles.container, className, styleContent.content)}>
    {htmlReactParse(children)}
  </div>
)

export default CaptionLine
