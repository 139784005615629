import clsx from 'clsx'
import { CSSProperties } from 'react'
import { UIVariantContext } from 'shared-components/contexts/UIVariantContext'
import Linked, { LinkedProps } from 'shared-components/service/Linked'
import { BasicStyledComponent } from 'shared-definitions/types'
import stylesCommon from './BasicButton.module.css'

type BasicButtonLinkProps = BasicStyledComponent & LinkedProps & { style?: CSSProperties }

const BasicButtonLink: React.FC<BasicButtonLinkProps & React.PropsWithChildren> = ({
  style,
  children,
  className,
  href,
  target,
  onClick,
  title,
  ...props
}) => {
  const { buttonStyle } = UIVariantContext.useContainer()
  return (
    <Linked
      onClick={onClick}
      style={style}
      className={clsx(stylesCommon.container, className, {
        [stylesCommon.fill]: buttonStyle === 'fill',
        [stylesCommon.outline]: buttonStyle === 'outline',
      })}
      href={href}
      target={target}
      title={title}
      {...props}
    >
      {children}
    </Linked>
  )
}

export default BasicButtonLink
