import { htmlReactParse } from 'shared-code/html'
import styles from './EditButton.module.css'
import BasicButtonLink from 'shared-components/buttons/BasicButtonLink'
import clsx from 'clsx'
import { BasicStyledComponent, LinkedLinkProps } from 'shared-definitions/types'
import { useRouter } from 'next/router'

interface EditButtonProps extends BasicStyledComponent {
  pLink: LinkedLinkProps | null
  pLinkRequestUpdate: LinkedLinkProps | null
  elevated?: boolean
  absolute?: boolean
}

const EditButton: React.VFC<EditButtonProps> = ({
  absolute,
  className,
  pLink,
  pLinkRequestUpdate,
  elevated,
}) => {
  const { isPreview } = useRouter()
  if (!isPreview) {
    return null
  }

  if (!pLink || !pLinkRequestUpdate) {
    return null
  }

  return (
    <div
      className={clsx(styles.container, className, {
        [styles.container]: !absolute,
        [styles.containerAbsolute]: absolute,
      })}
    >
      <div className={clsx(styles.subContainer, { [styles.elevated]: elevated })}>
        <BasicButtonLink {...pLink} className={clsx(styles.button)}>
          {'Edit'}
        </BasicButtonLink>
        <BasicButtonLink
          {...pLinkRequestUpdate}
          className={clsx(styles.button, { [styles.elevated]: elevated })}
        >
          {htmlReactParse('&circlearrowright;')}
        </BasicButtonLink>
      </div>
    </div>
  )
}

export default EditButton
