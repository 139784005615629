/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import styles from './NCExpandableImg.module.css'
import { DSNCExpandableImage } from 'shared-definitions/types'
import styleUtils from 'shared-components/styles/util.module.css'
import ImageBlock from 'shared-components/ui/ImageBlock'
import { PopupContext } from 'shared-components/contexts/PopupContext'
import dynamic from 'next/dynamic'
import viewport from 'shared-definitions/viewport.json'

const ImageBlockPopup = dynamic(() => import('shared-components/ui/ImageBlockPopup'))

interface NCExpandableImgProps {
  data: DSNCExpandableImage
  index: number
}

const NCExpandableImg: React.VFC<NCExpandableImgProps> = ({ data: { badges, image }, index }) => {
  const { setPopupOpened, setPopupContent } = PopupContext.useContainer()
  function zoomIn(): void {
    setPopupContent(
      <ImageBlockPopup
        className={styles.popupBlock}
        image={image}
        onClose={() => setPopupOpened(false)}
      />,
      styles.popupContainer
    )
    setPopupOpened(true)
  }

  return (
    <div tabIndex={0} role="button" className={styleUtils.mobileFull} onClick={zoomIn}>
      <ImageBlock
        cutLinks
        badges={badges}
        sizes={[{ bp: 'lg', val: viewport['lg'] }, '80vw']}
        image={{
          ...image,
          // cutting links because they are covered by button
          credits: image.credits.map(i => ({
            title: i.title,
            pLink: null,
          })),
        }}
        preload={index === 0}
      />
    </div>
  )
}

export default NCExpandableImg
