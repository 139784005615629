import { RefObject, useEffect, useRef, useState } from 'react'

interface UseScrollHeightProps<T> {
  height: number
  ref: RefObject<T>
}

export function useScrollHeight<T extends HTMLElement>(): UseScrollHeightProps<T> {
  const ref = useRef<T>(null)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    setHeight(ref.current.scrollHeight)
    if (!window.ResizeObserver) {
      return
    }

    const resizeObserver = new ResizeObserver(([entry]) => {
      setHeight(entry.target.scrollHeight)
    })

    resizeObserver.observe(ref.current)
    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return { ref, height }
}
