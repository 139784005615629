import { DSNCString } from 'shared-definitions/types'
import styleUtils from 'shared-components/styles/util.module.css'
import styleContent from 'shared-components/styles/content.module.css'
import { htmlReactParse } from 'shared-code/html'
import clsx from 'clsx'
import { useScrollableLinksContainer } from 'shared-components/hooks/use-scrollbale-links-container'
import composeRefs from '@seznam/compose-react-refs'
import { useTooltipsContainer } from 'shared-components/hooks/use-tooltips-container'

interface NCStringProps {
  data: DSNCString
}

const NCString: React.VFC<NCStringProps> = ({ data }) => (
  <div
    ref={composeRefs(useScrollableLinksContainer(), useTooltipsContainer())}
    className={clsx(styleUtils.nc, styleContent.content)}
  >
    {htmlReactParse(data.html)}
  </div>
)

export default NCString
