import clsx from 'clsx'
import React from 'react'

import type { BasicStyledComponent } from 'shared-definitions/types'
import Cart from 'shared-svg/Cart.svg'
import type { LinkedProps } from '../service/Linked'
import IconedButtonLink from './IconedButtonLink'

import stylesButton from './CartButtonLink.module.css'

const CartButtonLink: React.FC<BasicStyledComponent & React.PropsWithChildren & LinkedProps> = ({
  className,
  ...props
}) =>
  React.createElement(IconedButtonLink, {
    ...props,
    icon: Cart,
    className: clsx(stylesButton.container, className),
  })

export default CartButtonLink
