import clsx from 'clsx'
import React, { useEffect } from 'react'

import ArrowBird from 'shared-svg/ArrowRightBird.svg'
import DealShortCard from 'shared-components/deals/DealShortCard'
import type { DSNCDealsMediumCarousel } from 'shared-definitions/types'

import styles from './NCDealsMediumCarousel.module.css'
import styleUtils from 'shared-components/styles/util.module.css'
import { ResponsiveContext } from '../contexts/ResponsiveContext'

interface NCDealsMediumCarouselProps {
  data: DSNCDealsMediumCarousel
}

const NCDealsMediumCarousel: React.VFC<NCDealsMediumCarouselProps> = ({ data: { items } }) => {
  const [activeIndex, setActiveIndex] = React.useState(0)
  const [numOfColumns, setNumOfColumns] = React.useState(2)
  const { ready, xxl } = ResponsiveContext.useContainer()

  const sliderRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!ready) {
      return
    }

    if (xxl) {
      setNumOfColumns(3)
    } else {
      setNumOfColumns(2)
    }
  }, [ready, xxl])

  const scrollPrev = (): void => setActiveIndex(i => Math.max(0, i - 1))
  const scrollNext = (): void => setActiveIndex(i => Math.min(items.length - numOfColumns, i + 1))

  const cssVars = {
    '--active-index': activeIndex,
    '--items-length': items.length,
    '--num-of-columns': numOfColumns,
  } as React.CSSProperties

  const scrollDisabled = items.length <= numOfColumns

  return (
    <div className={clsx(styles.container, styleUtils.nc)} style={cssVars}>
      <div ref={sliderRef} className={styles.scroller}>
        <div className={styles.scrollView}>
          {items.map((item, key) => (
            <DealShortCard className={styles.card} key={key} item={item} />
          ))}
        </div>
      </div>
      <button
        type="button"
        className={clsx(styles.buttonPrev, { [styles.hidden]: scrollDisabled })}
        onClick={scrollPrev}
        disabled={activeIndex < 1}
      >
        <ArrowBird className={styles.iconPrev} />
      </button>
      <button
        type="button"
        className={clsx(styles.buttonNext, { [styles.hidden]: scrollDisabled })}
        onClick={scrollNext}
        disabled={activeIndex >= items.length - numOfColumns}
      >
        <ArrowBird className={styles.iconNext} />
      </button>
    </div>
  )
}

export default NCDealsMediumCarousel
