import { DSNCError } from 'shared-definitions/types'
import styles from './NCError.module.css'
import { htmlReactParse } from 'shared-code/html'
import styleUtils from 'shared-components/styles/util.module.css'
import CloseBold from 'shared-svg/CloseBold.svg'
import clsx from 'clsx'

interface NCErrorProps {
  data: DSNCError
}

const NCError: React.FC<NCErrorProps> = ({ data }) => (
  <div className={clsx(styles.container, styleUtils.nc)}>
    <CloseBold className={styles.icon} />
    {htmlReactParse(data.textHtml)}
  </div>
)

export default NCError
