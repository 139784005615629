import React, { useEffect, useRef } from 'react'

import type { DSNCScript } from 'shared-definitions/types'

export interface NCScriptProps {
  data: DSNCScript
}

const NCScript: React.VFC<NCScriptProps> = ({
  data: {
    props: { script },
  },
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const { current } = ref
    if (!current) {
      return
    }

    const scrEl = document.createElement('script')
    scrEl.type = script.type
    if ('src' in script) {
      scrEl.src = script.src
    }
    if ('text' in script) {
      const textNode = document.createTextNode(script.text)
      scrEl.appendChild(textNode)
    }

    for (const [key, value] of Object.entries(script.attributes)) {
      scrEl.setAttribute(key, value)
    }

    current.appendChild(scrEl)

    return () => {
      scrEl.remove()
    }
  }, [script])

  return <div ref={ref} />
}

export default NCScript
