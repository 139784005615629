import { useRef, useEffect, RefObject } from 'react'

export function useScrollableLinksContainer<T extends HTMLElement>(): RefObject<T> {
  const ref = useRef<T>(null)
  useEffect(() => {
    const { current } = ref
    if (!current) {
      return
    }

    const handler = (e: MouseEvent): void => {
      const { target } = e
      if (!(target instanceof HTMLAnchorElement)) {
        return
      }

      const url = new URL(target.href)

      if (url.origin === window.location.origin && url.pathname === window.location.pathname) {
        const scrollEl = document.getElementById(url.hash.slice(1))
        if (scrollEl) {
          e.preventDefault()
          scrollEl.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }

    current.addEventListener('click', handler)
    return () => current.removeEventListener('click', handler)
  }, [])

  return ref
}
