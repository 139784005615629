import { publicConfig } from 'config'
import { getds } from 'ds'
import { ComponentProps, useCallback } from 'react'
import RecommendList from '../components/posts/RecommendList'
import { DSNCRecommend } from 'shared-definitions/types'
import RecommendRow from '../components/posts/RecommendRow'
import styles from './NcRecommend.module.css'

interface NCRecommendProps {
  data: DSNCRecommend
}

type NCRecommendAA = ComponentProps<typeof RecommendList>
const NCRecommend: React.VFC<NCRecommendProps> = ({ data }) => {
  const getRecommend: NCRecommendAA['getRecommend'] = useCallback(async (...args) => {
    const ds = await getds()
    const result = await ds.getRecommend(...args)
    return result.data.recommendations?.items ?? []
  }, [])
  if (!publicConfig('showRecommendedPosts')) {
    return null
  }
  return (
    <RecommendList
      title={data.title}
      getRecommend={getRecommend}
      itemsInList={data.itemsInList}
      postCard={RecommendRow}
      titleClassName={styles.title}
      className={styles.recommendList}
    />
  )
}

export default NCRecommend
