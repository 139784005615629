import styleUtils from 'shared-components/styles/util.module.css'
import styles from './NCPromoted.module.css'
import clsx from 'clsx'
import Fieldset from 'shared-components/ui/Fieldset'
import type { ComponentType } from 'react'
import type { DSNCPromoted, DSResource, NCComponentInterface } from 'shared-definitions/types'

interface NCPromotedProps<T extends DSResource> {
  data: DSNCPromoted<T>
  nc: ComponentType<NCComponentInterface<T>>
}

const NCPromoted = <T extends DSResource>({
  data,
  nc: NC,
}: NCPromotedProps<T>): React.ReactElement => (
  <div className={clsx(styles.container, styleUtils.nc, { [styles.empty]: !data.blocks.length })}>
    {data.blocks.length ? (
      <Fieldset legendProps={{ content: 'Promoted' }}>
        {data.blocks?.map((colItem, colIndex) => (
          <NC index={colIndex} key={colIndex} data={colItem} tpl="in-column" />
        ))}
      </Fieldset>
    ) : (
      <span className={styles.title}>{'Promoted'}</span>
    )}
  </div>
)

export default NCPromoted
