import { browserCached } from 'shared-code/cache'
import {
  VileModel,
  VileModelMeasure,
  VileModelMeasureResponse,
  VileModelResponse,
} from 'shared-definitions/types'

export async function getVileModels(apiUrl: string): Promise<VileModelResponse> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const vileModelResponse: VileModelResponse = await browserCached('clearbuy-models', async () => {
    const response = await fetch(`${apiUrl}api/vile/models`, {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const modelResponse: VileModel[] = await response.json()

    return { models: modelResponse }
  })

  return vileModelResponse
}
export async function getVileModelMeasures(
  apiUrl: string,
  modelId: number
): Promise<VileModelMeasureResponse> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const vileModelMeasuresResponse: VileModelMeasureResponse = await browserCached(
    `clearbuy-model-measures-${modelId}`,
    async () => {
      const response = await fetch(`${apiUrl}api/vile/measurements?id=${modelId}`, {
        method: 'GET',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
      })

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const modelResponse: VileModelMeasure[] = await response.json()

      return { measures: modelResponse }
    }
  )

  return vileModelMeasuresResponse
}
