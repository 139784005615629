import { DSNCDealsMedium, DSTpl } from 'shared-definitions/types'
import clsx from 'clsx'
import styleUtils from 'shared-components/styles/util.module.css'
import styles from './NCDealsMedium.module.css'
import DealShortCard from 'shared-components/deals/DealShortCard'

interface NCDealsMediumProps {
  data: DSNCDealsMedium
  tpl: DSTpl
}

const NCDealsMedium: React.VFC<NCDealsMediumProps> = ({ data, tpl }) => (
  <div
    className={clsx(styles.container, styleUtils.nc, {
      [styles.containerHollow]: data.items.length < 3,
      [styles.wideContainer]: tpl !== 'sidebar' && tpl !== 'in-column',
    })}
  >
    {data.items.map((item, index) => (
      <DealShortCard key={index} item={item} className={styles.card} />
    ))}
  </div>
)

export default NCDealsMedium
