import { FC, createElement } from 'react'
import {
  DSResource,
  MapDiscriminatedComponent,
  NCComponentInterface,
  NCConcreteComponentInterface,
} from 'shared-definitions/types'

// todo: redo types

export function componentMapper<T extends DSResource>(
  map: MapDiscriminatedComponent<T, 'resource'>
): React.VFC<NCComponentInterface<T>> {
  return function nc({ data, tpl, index }) {
    const render = map[data.resource as T['resource']]
    if (!render) {
      return null
    }

    return createElement(render, {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data: data as any,
      tpl,
      index,
      nc,
    })
  }
}

interface Opt<T> {
  condition: (data: NCComponentInterface<T>) => boolean
  component: FC<NCComponentInterface<T>>
}

export function ncABTest<T extends object, A>(
  opts: Opt<T>[]
): FC<NCConcreteComponentInterface<T, A>> {
  return function abTest(feed) {
    for (const opt of opts) {
      if (opt.condition(feed)) {
        return createElement(opt.component, feed)
      }
    }

    throw new Error('No a/b test found')
  }
}
