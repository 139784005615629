import { useRef, useEffect } from 'react'

interface MessageSetHeight {
  height: number
}

interface MessageSetParent {
  parentUrl: string
}

type IframeMessageEvent = globalThis.MessageEvent<
  MessageSetParent | MessageSetHeight | 'iframeReady'
>

interface UseQuizIframeOpts {
  ipGetterApi: string
}

interface UseQuizIframeResult {
  refIframe: React.RefObject<HTMLIFrameElement>
}

export function useQuizIframe(opts: UseQuizIframeOpts): UseQuizIframeResult {
  const refIframe = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const listener = (event: IframeMessageEvent): void => {
      const iframe = refIframe.current
      if (!iframe) {
        console.error('iframe', 'Element not found.')
        return
      }

      // Ensure the message is from the expected iframe
      if (event.source !== iframe.contentWindow) {
        return
      }

      const ed = event.data
      if (ed === 'iframeReady') {
        void getIp(opts.ipGetterApi).then(ip => {
          sendParentUrlToIframe(iframe, ip)
        })
      } else if ('parentUrl' in ed) {
        console.log('iframe', 'Received parent URL:', ed.parentUrl)
      } else if ('height' in ed) {
        console.log('iframe', 'Set height received', ed.height)
        iframe.style.height = ed.height + 'px'
      }
    }

    window.addEventListener('message', listener)
    return () => {
      window.removeEventListener('message', listener)
    }
  }, [opts.ipGetterApi])

  return {
    refIframe,
  }
}

async function getIp(apiEndpoint: string): Promise<string> {
  try {
    const response = await fetch(apiEndpoint)
    const data = (await response.json()) as { ip: string }
    return data.ip
  } catch (error) {
    console.log('Error:', error)
    return 'unable to fetch ip'
  }
}

function getOperatingSystem(): string {
  let user_device_os = 'not_specified'
  const platform = navigator.platform
  const userAgent = navigator.userAgent

  if (/Win/.test(platform)) {
    user_device_os = 'Windows'
  } else if (/Mac/.test(platform)) {
    user_device_os = 'MacOS'
  } else if (/Linux/.test(platform)) {
    user_device_os = 'Linux'
  } else if (/Android/.test(userAgent)) {
    user_device_os = 'Android'
  } else if (/like Mac/.test(userAgent) && 'ontouchend' in document) {
    user_device_os = 'iOS'
  } else {
    user_device_os = 'Unknown'
  }

  return user_device_os
}

function getDeviceType(): string {
  let user_device_type = 'not_specified'
  const userAgent = navigator.userAgent
  if (/Mobi|Android/i.test(userAgent)) {
    user_device_type = 'Mobile'
  } else if (/Tablet|iPad/i.test(userAgent)) {
    user_device_type = 'Tablet'
  } else {
    user_device_type = 'Desktop'
  }
  return user_device_type
}

function sendParentUrlToIframe(iframe: HTMLIFrameElement, userIp: string): void {
  const userOS = getOperatingSystem()
  const userDevice = getDeviceType()
  const parentUrl = window.location.href

  const message = {
    parentUrl,
    userIp,
    userOS,
    userDevice,
  }
  console.log('message: ', message)

  iframe.contentWindow?.postMessage(message, '*')
}
