import React from 'react'
import { BasicStyledComponent } from 'shared-definitions/types'
import styles from './CouponSuggestion.module.css'
import clsx from 'clsx'

interface CustomTextProps extends BasicStyledComponent {
  code: string | null
}
const CustomText: React.VFC<CustomTextProps> = ({ code, className }) => (
  <div className={clsx(styles.container, className)}>
    <span className={styles.code}>{code}</span>
  </div>
)

export default CustomText
