import { AffilateContext } from 'shared-components/contexts/AffilateContext'
import { useInView } from 'react-intersection-observer'
import { useEffect, useRef } from 'react'
import { DSNCAffilateSection } from 'shared-definitions/types'

interface NCAffilateSectionProps {
  data: DSNCAffilateSection
}

const NCAffilateSection: React.VFC<NCAffilateSectionProps> = ({ data }) => {
  const { bars, setState } = AffilateContext.useContainer()
  const { ref, inView, entry } = useInView({ initialInView: false })
  const scrollLast = useRef(0)

  useEffect(() => {
    const hasScrolledUp = window.scrollY - scrollLast.current < 0
    scrollLast.current = window.scrollY

    if (!entry) {
      return
    }

    if (!inView) {
      if (hasScrolledUp && data.id === 0) {
        // fix: set activeIndex = 0 if scroll top and prev activeIndex == 1
        setState(s => ({ ...s, activeIndex: 0 }))
      }
      return
    }

    setState(s => {
      const isScrollUp = entry.boundingClientRect.y < window.innerHeight / 2
      const newVal = data.id + (isScrollUp ? 0 : 1)
      if (newVal === s.activeIndex) {
        return s
      }

      return { ...s, activeIndex: Math.min(newVal, bars.length - 1) }
    })
  }, [inView, setState, bars, data, entry])

  return <div ref={ref} />
}

export default NCAffilateSection
