/* eslint-disable react/display-name */
import clsx from 'clsx'
import React from 'react'
import Linked from 'shared-components/service/Linked'
import { BasicStyledComponent, DSDataAttrs, DSPostShortWithAuthor } from 'shared-definitions/types'
import styles from './RecommendRow.module.css'

interface PostCardRelatedProps extends BasicStyledComponent, DSDataAttrs {
  post: DSPostShortWithAuthor
  hideCategory?: boolean
  unresponsive?: boolean
}

let c = 1
const RecommendRow: React.VFC<PostCardRelatedProps> = React.memo(
  ({ post, className, unresponsive, ...props }) => {
    c++

    return (
      <li
        className={clsx(styles.container, className, { [styles.unresponsive]: unresponsive })}
        {...props}
      >
        <Linked aria-hidden="true" {...post.pLink} {...props} className={styles.overlayLink}>
          {post.title}
        </Linked>
        <div className={styles.info}>
          <div className={styles.title} id={`post-card-related-${c}`}>
            {post.title}
          </div>
        </div>
      </li>
    )
  }
)

export default RecommendRow
