import { DSNCApp } from 'shared-definitions/types'
import styleUtils from 'shared-components/styles/util.module.css'
import styles from './NCApp.module.css'
import clsx from 'clsx'
import OfferButtonLink from 'shared-components/buttons/OfferButtonLink'
import AppImage from 'shared-components/ui/AppImage'
import StoreIcon from 'shared-components/service/StoreIcon'
import LazyLoad from 'shared-components/service/LazyLoad'
import EditButton from 'shared-components/service/EditButton'
import dynamic from 'next/dynamic'
import { priceCurrencyText } from 'shared-code/format'

const Youtube = dynamic(() => import('react-player/youtube'))
const Video = dynamic(() => import('react-player/file'))

interface NCAppProps {
  data: DSNCApp
}

const NCApp: React.VFC<NCAppProps> = ({ data }) => {
  const [firstApp] = data.apps
  return (
    <article className={clsx(styles.content, styleUtils.nc)}>
      <EditButton pLink={data.pLinkEdit} pLinkRequestUpdate={null} />
      <h4 className={styles.title}>{data.name}</h4>
      <div className={styles.description}>{data.description}</div>
      {firstApp && firstApp.currency ? (
        <div className={styles.priceLine}>
          <span className={styles.priceLabel}>{'Price: '}</span>
          {priceCurrencyText({
            currency: firstApp.currency,
            current: firstApp.price,
            isFree: firstApp.isFree,
          })}
        </div>
      ) : null}
      <div className={clsx(styles.buttons, { [styles.buttonsMultiply]: data.apps.length > 1 })}>
        {data.apps.map((item, index) => (
          <OfferButtonLink
            innerClassName={styles.buttonInner}
            key={index}
            className={styles.button}
            {...item.link.pLink}
          >
            <span className={styles.buttonText}>{item.link.label}</span>
            <StoreIcon icon={item.icon} className={styles.buttonIcon} />
          </OfferButtonLink>
        ))}
      </div>
      {data.images.length ? (
        <div className={styles.gallery}>
          {data.images.map((item, index) => (
            <div className={styles.picture} key={index}>
              <AppImage layout="responsive" ratio={{ w: 230, h: 410 }} image={item} sizes={230} />
            </div>
          ))}
        </div>
      ) : null}
      {data.video ? (
        <LazyLoad layout="raw" className={clsx(styles.video, styleUtils.mobileFull)}>
          {() => {
            if (!data.video) {
              return null
            }
            if ('youtubeId' in data.video) {
              return (
                <Youtube
                  className={styles.player}
                  width="100%"
                  height="100%"
                  controls
                  url={`https://www.youtube.com/watch?v=${data.video.youtubeId}`}
                />
              )
            }
            if ('videoUrl' in data.video) {
              return (
                <Video
                  className={styles.player}
                  width="100%"
                  height="100%"
                  controls
                  url={data.video.videoUrl}
                />
              )
            }
          }}
        </LazyLoad>
      ) : null}
    </article>
  )
}

export default NCApp
