import { DSNCImgComparison } from 'shared-definitions/types'
import clsx from 'clsx'
import styles from './NCImgComparison.module.css'
import ReactCompareImage from 'react-compare-image'

interface NCImgComparisonProps {
  data: DSNCImgComparison
}

const NCImgComparison: React.VFC<NCImgComparisonProps> = ({ data }) => (
  <div className={clsx(styles.container)}>
    <ReactCompareImage
      handle={
        <div className={styles.handle}>
          <div className={styles.handleDiv} />
          <div className={styles.handleDiv} />
        </div>
      }
      leftImageLabel={data.a.title}
      leftImage={data.a.image.src}
      rightImage={data.b.image.src}
      rightImageLabel={data.b.title}
    />
  </div>
)

export default NCImgComparison
