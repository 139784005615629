import styles from './NCNewsTitle.module.css'
import { DSNCNewsTitle } from 'shared-definitions/types'

interface NCNewsTitleProps {
  data: DSNCNewsTitle
}

const NCNewsTitle: React.VFC<NCNewsTitleProps> = ({ data }) => (
  <div className={styles.container}>{data.text}</div>
)

export default NCNewsTitle
