import type { DSShortcodeAnalytics, DSShortcodeEvent } from 'shared-definitions/types'
import { PostContext } from '../contexts/PostContext'

interface UseShortcodeAnalyticsResult {
  trackEvent: (event: DSShortcodeEvent) => Promise<void>
}

export function useShortcodeAnalytics(
  opts: DSShortcodeAnalytics | null
): UseShortcodeAnalyticsResult {
  const ctx = PostContext.useContainer()
  if (!opts) {
    return {
      trackEvent: async () => {},
    }
  }

  return {
    trackEvent: async event => {
      await fetch(opts.endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          entity_id: opts.entity.id,
          entity_type: opts.entity.type,
          post_id: ctx.postId,
          event_type: event.type,
          post_title: ctx.title,
          post_link: ctx.pagePath,
        }),
      })
    },
  }
}
