import clsx from 'clsx'
import { PlaywireContext } from 'shared-components/contexts/PlaywireContext'
import { PostContext } from 'shared-components/contexts/PostContext'
import styleUtils from 'shared-components/styles/util.module.css'
import { DSNCAdClassed } from 'shared-definitions/types'
import { ResponsiveContext } from '../contexts/ResponsiveContext'
import styles from './NCAdClassed.module.css'

interface NCAdClassedProps {
  data: DSNCAdClassed
}

const NCAdClassed: React.VFC<NCAdClassedProps> = ({ data }) => {
  const { disabledAds } = PostContext.useContainer()
  const { ready, md } = ResponsiveContext.useContainer()
  const { enabled, adClassList } = PlaywireContext.useContainer()
  if (disabledAds || !enabled) {
    return null
  }

  const show =
    ready &&
    ((data.visible === 'desktop' && md) ||
      (data.visible === 'mobile' && !md) ||
      data.visible === 'all')
  if (!show) {
    return null
  }

  return (
    <div
      className={clsx(...adClassList, styleUtils.mobileFull, data.className, styles.ad, {
        [styles.desktop]: data.visible === 'desktop',
        [styles.mobile]: data.visible === 'mobile',
        [styles.video]: data.type === 'video',
      })}
    >
      <div className="pw-incontent" />
    </div>
  )
}

export default NCAdClassed
