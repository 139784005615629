/* eslint-disable react/display-name */
import clsx from 'clsx'
import EditButton from 'shared-components/service/EditButton'
import stylesDeals from 'shared-components/styles/deals.module.css'
import styleUtils from 'shared-components/styles/util.module.css'
import AppImage from 'shared-components/ui/AppImage'
import Score from 'shared-components/ui/Score'
import SplitterLine from 'shared-components/ui/SplitterLine'
import { DSNCDealsLarge } from 'shared-definitions/types'
import styles from './NCDealsLargeGrid.module.css'
import { priceFormat, wrapWithCurrency } from 'shared-code/format'
import { AppEventsContext } from '../contexts/AppEventsContext'
import TextBadge from '../ui/TextBadge'
import OfferButtonLink from '../buttons/OfferButtonLink'
import DiscountButtonWrapper from '../deals/DiscountButtonWrapper'
import React from 'react'

interface NCDealsLargePropsGrid {
  data: DSNCDealsLarge
}

const NCDealsLargeGrid = React.forwardRef<HTMLDivElement, NCDealsLargePropsGrid>(
  ({ data }, ref) => {
    const { appEvent } = AppEventsContext.useContainer()
    const [badge] = data.badges
    const [badgeImage] = badge && badge.images ? badge.images : []

    return (
      <>
        <EditButton pLink={data.pLinkEdit} pLinkRequestUpdate={data.pLinkRequestUpdate} elevated />
        <div
          {...data.dataAttrs}
          ref={ref}
          className={clsx(styles.container, stylesDeals.bg, stylesDeals.border, styleUtils.nc)}
        >
          {data.anchor && (
            <a id={data.anchor} className={styleUtils.anchor}>
              {data.title}
            </a>
          )}
          <div className={clsx(styles.imageContainer)}>
            {data.textBadge && <TextBadge {...data.textBadge} className={styles.textBadge} />}
            {data.image ? (
              <AppImage
                layout="raw"
                className={clsx(styles.image, stylesDeals.border)}
                image={data.image}
                sizes={[
                  { bp: 'lg', val: 'rem(200)' },
                  { bp: 'sm', val: 'calc(100vw / 3)' },
                  'calc(100vw - rem(40))',
                ]}
              />
            ) : null}
          </div>
          <div className={styles.badgeWrapper}>
            {badgeImage ? (
              <AppImage
                layout="raw"
                image={badgeImage}
                className={styles.badge}
                imageClassName={styles.badgeImage}
                sizes={['rem(39)']}
              />
            ) : null}
          </div>
          <div className={styles.title}>{data.title}</div>
          {data.tags.length ? <div className={styles.tags}>{data.tags.join(' • ')}</div> : null}
          <div className={styles.underTags}>
            {data.score ? <Score className={styles.score} score={data.score} /> : null}
            {data.msrp ? (
              <span className={styles.msrp}>
                {'MSRP: '}
                {wrapWithCurrency(priceFormat(data.msrp.price), data.msrp.currency)}
              </span>
            ) : null}
            <SplitterLine className={styles.separator} variant="bright" />
          </div>
          {data.subtitle ? <div className={styles.subtitle}>{data.subtitle}</div> : null}
          {data.text ? <div className={styles.text}>{data.text}</div> : null}

          <div
            className={styles.buttonsGrid}
            style={{
              gridTemplateRows: `repeat(${Math.ceil(data.buttons.length / 2)}, auto)`,
            }}
          >
            {data.buttons.map((item, index) => (
              <DiscountButtonWrapper key={index} info={item.price}>
                <OfferButtonLink
                  className={styles.button}
                  {...item.link.pLink}
                  onClick={() => {
                    appEvent({
                      event: 'widget-interaction',
                      widgetType: 'Product Widget',
                      label: item.link.label,
                      url: item.link.pLink.href,
                    })
                  }}
                  data-sel="large-deal-button"
                >
                  {item.link.label}
                </OfferButtonLink>
              </DiscountButtonWrapper>
            ))}
          </div>
        </div>
      </>
    )
  }
)

export default NCDealsLargeGrid
