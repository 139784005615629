import clsx from 'clsx'
import CouponSuggestion from 'shared-components/deals/CouponSuggestion'
import { BasicStyledComponent, DSDealsLatestCard } from 'shared-definitions/types'
import ArrowRightShade from 'shared-svg/ArrowRightShade.svg'
import DoubleArrow from 'shared-svg/DoubleArrow.svg'
import EditButton from '../service/EditButton'
import Linked from '../service/Linked'
import AppImage from './AppImage'
import styles from './DealsLatestCard.module.css'

interface DSDealsLatestCardComponentProps {
  onClick: () => void
}

const DealsLatestCard: React.VFC<
  DSDealsLatestCard & DSDealsLatestCardComponentProps & BasicStyledComponent
> = ({
  title,
  image,
  priceButton,
  discountString,
  className,
  pLinkEdit,
  pLinkRequestUpdate,
  dataAttrs,
  coupon,
  customText,
  onClick,
}) => (
  <div className={clsx(className, styles.container)} {...dataAttrs}>
    <EditButton pLink={pLinkEdit} pLinkRequestUpdate={pLinkRequestUpdate} className={styles.edit} />

    {priceButton && (
      <Linked {...priceButton?.link.pLink} className={styles.imageContainer} onClick={onClick}>
        {image && <AppImage image={image} sizes={90} layout="fill" />}
      </Linked>
    )}
    {!priceButton && (
      <div className={styles.imageContainer}>
        {image && <AppImage image={image} sizes={90} layout="fill" />}
      </div>
    )}

    <div className={styles.content}>
      {priceButton && (
        <Linked {...priceButton?.link.pLink} className={styles.title} onClick={onClick}>
          {title}
        </Linked>
      )}
      {!priceButton && <h5 className={styles.title}>{title}</h5>}
      {discountString && (
        <div className={styles.discount}>
          <DoubleArrow className={styles.discountIcon} />
          {discountString}
        </div>
      )}
      {priceButton && (
        <Linked
          onClick={onClick}
          data-sel="deal-list"
          className={clsx(styles.priceButton, {
            [styles.buttonWithDiscount]: discountString,
          })}
          {...priceButton.link.pLink}
        >
          {priceButton.link.label}
          <ArrowRightShade className={styles.priceButtonIcon} />
        </Linked>
      )}
      {coupon && (
        <>
          <div className={styles.couponFirstCol} />
          <CouponSuggestion code={coupon} className={styles.couponInfo} />
        </>
      )}
      {!coupon && customText && (
        <>
          <div className={styles.couponFirstCol} />
          <div className={clsx(styles.couponInfo, styles.couponCustomText)}>{customText}</div>
        </>
      )}
    </div>
  </div>
)

export default DealsLatestCard
