import { BasicStyledComponent } from 'shared-definitions/types'
import LazyLoad, { LazyLoadComposed } from 'shared-components/service/LazyLoad'
import { ComponentType, CSSProperties } from 'react'

interface NCLazyProps<T> extends BasicStyledComponent {
  style?: CSSProperties
  props: T
  component: ComponentType<T>
}

const NCLazy = <T,>({
  style,
  className,
  props,
  component: Component,
  ...lazyProps
}: NCLazyProps<T> & LazyLoadComposed): React.ReactElement => (
  <LazyLoad style={style} {...lazyProps} className={className}>
    {() => <Component key="lazy" {...props} />}
  </LazyLoad>
)

export default NCLazy
