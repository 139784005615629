/* eslint-disable @typescript-eslint/no-unused-vars */
import styleContent from 'shared-components/styles/content.module.css'
import { DSNCTwitter } from 'shared-definitions/types'
import TwitterTweet from 'shared-components/twitter/TwitterTweet'
import { htmlReactParse } from 'shared-code/html'

interface NCTwitterProps {
  data: DSNCTwitter
}

const NCTwitter: React.VFC<NCTwitterProps> = ({ data }) => (
  <TwitterTweet
    className={styleContent.content}
    id={data.id}
    options={{
      align: 'center',
    }}
  >
    {data.fallbackHtml ? <>{htmlReactParse(data.fallbackHtml)}</> : null}
  </TwitterTweet>
)

export default NCTwitter
