import { BasicStyledComponent, DSSpecRow } from 'shared-definitions/types'
import styles from './SpecTableNodes.module.css'
import { CSSProperties, forwardRef } from 'react'
import clsx from 'clsx'
import SpecTableNode from './SpecTableNode'

interface SpecTableNodesProps extends BasicStyledComponent {
  rows: DSSpecRow[]
  variant: 'expanded' | 'collapsed'
  limitRows: number
}

// eslint-disable-next-line react/display-name
const SpecTableNodes = forwardRef<HTMLTableElement, SpecTableNodesProps>(
  ({ className, rows, limitRows, variant }, ref) => {
    const rowsToPrint = rows.slice(0, variant === 'collapsed' ? limitRows : Infinity)

    return (
      <table
        style={{ '--columns': rows[0]?.cells.length ?? 0 } as CSSProperties}
        className={clsx(className, styles.container, {
          [styles.collapsed]: variant === 'collapsed',
          [styles.expanded]: variant === 'expanded',
        })}
        ref={ref}
      >
        <tbody className={styles.body}>
          {rowsToPrint.map((row, rowIndex) => (
            <tr key={rowIndex} className={styles.row}>
              {row.cells.map((cell, cellIndex) => (
                <td key={cellIndex} className={styles.cell}>
                  {cell.content.map((item, itemIndex) => (
                    <SpecTableNode item={item} key={itemIndex} />
                  ))}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    )
  }
)

export default SpecTableNodes
