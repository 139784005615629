import styles from './BestComponentHeader.module.css'
import clsx from 'clsx'
import { BasicStyledComponent, DSSoundLabel } from 'shared-definitions/types'
import SoundLabel from 'shared-components/ui/SoundLabel'
import Score from 'shared-components/ui/Score'

interface BestComponentHeaderProps extends BasicStyledComponent {
  title: string
  score: number | null
  label: DSSoundLabel | null
}

const BestComponentHeader: React.VFC<BestComponentHeaderProps> = ({
  className,
  title,
  score,
  label,
}) => (
  <div className={clsx(styles.container, className)}>
    <div className={styles.title}>{title}</div>
    {label ? <SoundLabel className={styles.label} label={label} /> : null}
    {score ? (
      <>
        <Score score={score} />
        <div>{score}</div>
      </>
    ) : null}
  </div>
)

export default BestComponentHeader
