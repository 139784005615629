import { BasicStyledComponent, DSSpecItem } from 'shared-definitions/types'
import styles from './SpecTableNode.module.css'
import clsx from 'clsx'
import { htmlReactParse } from 'shared-code/html'

interface SpecTableNodeProps {
  item: DSSpecItem
}

const SpecTableNode: React.FC<SpecTableNodeProps & BasicStyledComponent> = ({
  item,
  className,
}) => {
  if (item.type === 'string') {
    return <p className={clsx(className, styles.string)}>{htmlReactParse(item.content)}</p>
  }

  if (item.type === 'block') {
    return (
      <div className={clsx(className, styles.block)}>
        {item.content.map((blockItem, blockIndex) => (
          <SpecTableNode item={blockItem} key={blockIndex} className={className} />
        ))}
      </div>
    )
  }

  return (
    <ul className={clsx(className, styles.list)}>
      {item.content.map((listItem, listItemIndex) => (
        <li className={styles.listItem} key={listItemIndex}>
          {listItem.content.map((listItemContent, listItemContentIndex) => (
            <SpecTableNode
              item={listItemContent}
              key={listItemContentIndex}
              className={className}
            />
          ))}
        </li>
      ))}
    </ul>
  )
}

export default SpecTableNode
