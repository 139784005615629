import styles from './CouponSuggestion.module.css'
import clsx from 'clsx'
import { BasicStyledComponent } from 'shared-definitions/types'
import { useCopy } from 'shared-components/hooks/use-copy'

interface CouponSuggestionProps extends BasicStyledComponent {
  code: string
}

const CouponSuggestion: React.VFC<CouponSuggestionProps> = ({ code, className }) => {
  const { copy } = useCopy()
  return (
    <button type="button" className={clsx(styles.container, className)} onClick={() => copy(code)}>
      <span className={styles.code}>{'Use code: '}</span>
      <span className={styles.codeContent}>{code}</span>
    </button>
  )
}

export default CouponSuggestion
