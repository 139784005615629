import Linked from 'shared-components/service/Linked'
import clsx from 'clsx'
import { BasicStyledComponent, DSImage, LinkedLinkProps } from 'shared-definitions/types'
import styles from './PreviewPostLink.module.css'
import AppImage from 'shared-components/ui/AppImage'
import { BreakPointSize } from 'shared-code/responsive'
import viewport from 'shared-definitions/viewport.json'
import { CSSProperties } from 'react'

interface PostProps {
  title: string
  subtitle: string | null
  text?: string | null
  image: DSImage
}

type Variant = 'default' | 'large' | 'card'

interface PreviewPostLinkProps extends BasicStyledComponent, LinkedLinkProps {
  variant?: Variant
  post: PostProps
  responsive?: boolean
}

const PreviewPostLink: React.VFC<PreviewPostLinkProps> = ({
  variant = 'default',
  href,
  target,
  responsive,
  post,
  className,
}) => {
  const responsiveBreakpoints: Record<Variant, BreakPointSize | BreakPointSize[]> = {
    default: [{ bp: 'md', val: 160 }, 90],
    large: [{ bp: 'md', val: 240 }, 90],
    card: [{ bp: 'lg', val: viewport['lg'] }, { bp: 'md', val: viewport['md'] }, 90],
  }
  return (
    <Linked
      style={
        {
          '--image-width': post.image?.size?.w ?? 16,
          '--image-height': post.image?.size?.h ?? 9,
        } as CSSProperties
      }
      className={clsx(className, styles.container, {
        [styles.responsive]: responsive,
        [styles.variantDefault]: variant === 'default',
        [styles.variantLarge]: variant === 'large',
        [styles.variantCard]: variant === 'card',
      })}
      href={href}
      target={target}
    >
      <div className={styles.content}>
        <div className={styles.title}>{post.title}</div>
        {post.text && <div className={styles.text}>{post.text}</div>}
        <div className={styles.subtitle}>{post.subtitle}</div>
      </div>
      {post.image ? (
        <AppImage
          sizes={responsive ? responsiveBreakpoints[variant] : 90}
          layout="raw"
          className={styles.imageContainer}
          image={post.image}
        />
      ) : (
        <div className={styles.imageContainer} />
      )}
    </Linked>
  )
}

export default PreviewPostLink
