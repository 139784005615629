import clsx from 'clsx'
import { FC } from 'react'
import { BasicStyledComponent, DSTextBadge } from 'shared-definitions/types'
import styles from './TextBadge.module.css'

const TextBadge: FC<DSTextBadge & BasicStyledComponent> = ({ className, text, style }) => (
  <div
    className={clsx(className, styles.container, {
      [styles.styleWarning]: style === 'warning',
    })}
  >
    {text}
  </div>
)

export default TextBadge
