import styles from './ArrowedButton.module.css'
import clsx from 'clsx'
import { BasicStyledComponent, LinkedLinkProps } from 'shared-definitions/types'
import Linked from 'shared-components/service/Linked'
import ArrowRight from 'shared-svg/ArrowRightBird.svg'

interface ArrowedButtonProps extends BasicStyledComponent, LinkedLinkProps {
  children: string
}

const ArrowedButton: React.VFC<ArrowedButtonProps> = ({ children, className, href, target }) => (
  <Linked className={clsx(styles.container, className)} href={href} target={target}>
    {children}
    <ArrowRight className={styles.icon} />
  </Linked>
)

export default ArrowedButton
