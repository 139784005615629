import { DSNCSpecTable } from 'shared-definitions/types'
import styleUtils from 'shared-components/styles/util.module.css'
import styles from './NCSpecTable.module.css'
import ArrowBottomBird from 'shared-svg/ArrowBottomBird.svg'
import IconedButton from 'shared-components/buttons/IconedButton'
import clsx from 'clsx'
import React, { CSSProperties, useState } from 'react'
import EditButton from 'shared-components/service/EditButton'
import SpecTableNodes from '../ui/SpecTableNodes'
import { useScrollHeight } from '../hooks/use-scroll-height'

interface NCSpecTableProps {
  data: DSNCSpecTable
}

interface SpecTableHeaderProps {
  collapsible: boolean
  opened: boolean
  toggleLabel: string
  onToggle: () => void
  title: string
}

const SpecTableHeader: React.FC<SpecTableHeaderProps> = ({
  title,
  onToggle,
  opened,
  toggleLabel,
  collapsible,
}) => {
  if (!collapsible) {
    return <div className={styles.title}>{title}</div>
  }

  return (
    <button className={styles.title} type="button" onClick={onToggle}>
      {title}
      {collapsible && (
        <div className={styles.titleButton}>
          {toggleLabel}
          <ArrowBottomBird
            className={clsx(styles.buttonTilteIcon, {
              [styles.buttonTilteIconActive]: opened,
            })}
          />
        </div>
      )}
    </button>
  )
}

const NCSpecTable: React.VFC<NCSpecTableProps> = ({ data }) => {
  const collapsible = data.collapsedRows !== null

  const { ref, height } = useScrollHeight<HTMLTableElement>()
  const [opened, setOpened] = useState(!collapsible)
  const toggleLabel = opened ? data.labels.collapse : data.labels.expand

  const rows = opened || !collapsible ? data.rows : data.collapsedRows ?? []

  return (
    <div
      className={styleUtils.nc}
      style={{ '--nodes-scroll-height': `${height}px` } as CSSProperties}
    >
      <EditButton pLink={data.pLinkEdit} elevated pLinkRequestUpdate={data.pLinkRequestUpdate} />
      <SpecTableHeader
        onToggle={() => setOpened(s => !s)}
        title={data.title}
        collapsible={collapsible}
        opened={opened}
        toggleLabel={toggleLabel}
      />
      <div className={styles.nodesContainer}>
        <SpecTableNodes
          rows={rows}
          ref={ref}
          limitRows={Infinity}
          variant={opened ? 'expanded' : 'collapsed'}
        />
      </div>
      {collapsible ? (
        <IconedButton
          onClick={() => setOpened(s => !s)}
          className={clsx(styles.button, { [styles.buttonActive]: opened })}
        >
          {toggleLabel}
          <ArrowBottomBird className={styles.buttonIcon} />
        </IconedButton>
      ) : null}
    </div>
  )
}

export default NCSpecTable
