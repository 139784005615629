import { CommonDataContext } from 'shared-components/contexts/CommonDataContext'
import {
  DSAd,
  DSAdListItem,
  DSNCAd,
  DSResource,
  DSTpl,
  NCComponentInterface,
} from 'shared-definitions/types'
import styles from './NCAd.module.css'
import React, { useState, useEffect, ComponentType } from 'react'
import { logWarning } from 'shared-code/log'
import { PostContext } from 'shared-components/contexts/PostContext'
import Linked from 'shared-components/service/Linked'
import { CBAdsControlContext } from 'shared-components/contexts/CBAdsControlContext'
import clsx from 'clsx'

interface NCGetAdsArgs {
  ts: number
  id: string
  type: string
  postId: number | null
}

interface NCAdContext<T extends DSResource> {
  getAds: (args: NCGetAdsArgs) => Promise<DSAd<T>>
}

interface NCAdProps<T extends DSResource> {
  data: DSNCAd<T>
  tpl: DSTpl
  context: NCAdContext<T>
  nc: ComponentType<NCComponentInterface<T>>
}

const NCAd = <T extends DSResource>({
  data,
  tpl,
  context,
  nc: NC,
}: NCAdProps<T>): React.ReactElement | null => {
  const { ts } = CommonDataContext.useContainer()
  const cbAdsControlContainer = CBAdsControlContext.useOptionalContainer()
  const { disabledAds } = PostContext.useContainer()

  const [updated, setUpdated] = useState(false)
  const [isVoidAds, setIsVoidAds] = useState(false)
  const [adList, setAdList] = useState<readonly DSAdListItem<T>[]>(
    data.fallback ? [{ pLink: null, items: data.fallback }] : []
  )

  useEffect(() => {
    if (isVoidAds && cbAdsControlContainer) {
      cbAdsControlContainer.enumerateVoidAds()
    }
  }, [cbAdsControlContainer, isVoidAds])

  useEffect(() => {
    void (async () => {
      try {
        const ads = await context.getAds({ ...data, ts })
        if (ads.list.length) {
          setAdList(ads.list)
        }

        setIsVoidAds(!ads.list.length)
      } catch (e) {
        logWarning('Advanced placement error', e)
      }
      setUpdated(true)
    })()
  }, [data.id, data.type, data.postId, ts])

  if (disabledAds) {
    return null
  }

  return (
    <div className={clsx(styles.container, { [styles.blockHidden]: !updated })}>
      {adList.map((adItem, index) => {
        const components = adItem.items.map((component, componentIndex) => (
          <NC index={componentIndex} key={componentIndex} data={component} tpl={tpl} />
        ))

        if (adItem.pLink) {
          return (
            <Linked className={styles.linked} key={index} {...adItem.pLink}>
              {components}
            </Linked>
          )
        }
        return <React.Fragment key={index}>{components}</React.Fragment>
      })}
    </div>
  )
}

export default NCAd
