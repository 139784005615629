/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { DSSoundIcon } from 'shared-definitions/types'
import React from 'react'
import Comfort from 'shared-svg/Comfort.svg'
import Style from 'shared-svg/Style.svg'
import Features from 'shared-svg/Features.svg'
import Battery from 'shared-svg/Battery.svg'
import Sound from 'shared-svg/Sound.svg'
import Waterproof from 'shared-svg/Waterproof.svg'
import Noise from 'shared-svg/Noise.svg'
import SoundResistance from 'shared-svg/SoundResistance.svg'
import Durability from 'shared-svg/Durability.svg'
import Design from 'shared-svg/Design.svg'
import OnEars from 'shared-svg/OnEars.svg'
import OverEars from 'shared-svg/OverEars.svg'
import Earbuds from 'shared-svg/Earbuds.svg'
import Bass from 'shared-svg/Bass.svg'
import Money from 'shared-svg/Money.svg'
import Best from 'shared-svg/Best.svg'

const componentsMap: Record<DSSoundIcon, React.VFC> = {
  best: Best,
  bass: Bass,
  money: Money,
  on_ears: OnEars,
  over_ears: OverEars,
  earbuds: Earbuds,
  design: Design,
  noise: Noise,
  resistance: SoundResistance,
  waterproof: Waterproof,
  durability: Durability,
  sound: Sound,
  comfort: Comfort,
  style: Style,
  features: Features,
  battery: Battery,
  none: Best,
}

interface SoundIconProps extends React.SVGAttributes<SVGElement> {
  icon: DSSoundIcon
}

const SoundIcon: React.VFC<SoundIconProps> = ({ icon, ...props }) => {
  const Component = componentsMap[icon]
  if (Component) {
    return <Component {...props} />
  }
  return <Best {...props} />
}

export default SoundIcon
