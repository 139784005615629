import CustomText from './CustomText'
import styles from './DiscountButtonWrapper.module.css'
import CouponSuggestion from 'shared-components/deals/CouponSuggestion'
import clsx from 'clsx'
import { BasicStyledComponent, DSDealPrice } from 'shared-definitions/types'
import { priceFormat, wrapWithCurrency } from 'shared-code/format'

type Info = Pick<DSDealPrice, 'discount' | 'coupon' | 'currency' | 'isFree' | 'customText'>

export function getIsDiscount(info: Info): boolean {
  return !(info.isFree || (!info.discount && !info.customText && !info.coupon))
}

interface DiscountButtonWrapperProps extends BasicStyledComponent {
  info: Info
  children: React.ReactElement
  isShown?: boolean
}

const DiscountButtonWrapper: React.VFC<DiscountButtonWrapperProps> = ({
  children,
  info,
  className,
  isShown = true,
}) => {
  const isDiscount = getIsDiscount(info)
  if (!isDiscount || !isShown) {
    return children
  }

  return (
    <div className={clsx(styles.container, className)}>
      {children}
      <div className={styles.line}>
        {info.discount ? (
          <div className={styles.save}>
            <span className={styles.saveContent}>{'Save '}</span>
            <span className={styles.discount}>
              {wrapWithCurrency(priceFormat(info.discount), info.currency)}
            </span>
          </div>
        ) : null}
        {info.customText || !info.discount ? (
          <CustomText
            code={info.customText}
            className={!info.discount ? styles.unBordered : styles.coupon}
          />
        ) : null}
        {info.coupon && !info.customText && info.discount ? (
          <CouponSuggestion
            code={info.coupon}
            className={!info.discount ? styles.unBordered : styles.coupon}
          />
        ) : null}
      </div>
    </div>
  )
}

export default DiscountButtonWrapper
