import styles from './NCSection.module.css'
import { DSNCSection } from 'shared-definitions/types'

interface NCSectionProps {
  data: DSNCSection
}

const NCSection: React.VFC<NCSectionProps> = ({ data }) => (
  <span className={styles.container}>
    <div className={styles.anchor} id={data.anchor} />
  </span>
)

export default NCSection
