import React, { useState, useCallback, type ReactNode, type VFC } from 'react'
import clsx from 'clsx'
import DiscountButtonWrapper, { getIsDiscount } from 'shared-components/deals/DiscountButtonWrapper'
import OfferButtonLink from 'shared-components/buttons/OfferButtonLink'
import styles from './AccordionDeal.module.css'
import Accordion from './Accordion'
import ArrowBottomBird from 'shared-svg/ArrowBottomBird.svg'
import type { DSDataAttrs, DSDealButton } from 'shared-definitions/types'

type StandardButtonView = 'discount' | 'btn'

export interface StandardDealButtonProps extends DSDataAttrs {
  data: DSDealButton
  className?: string
  view?: StandardButtonView
  onClick?: () => void
}

export const StandardDealButton: VFC<StandardDealButtonProps> = ({
  data,
  className,
  view = 'discount',
  ...rest
}) => {
  const isDiscount = getIsDiscount(data.price) && view === 'discount'

  return (
    <DiscountButtonWrapper
      isShown={!(view === 'btn')}
      info={data.price}
      className={styles.discountWrapper}
    >
      <OfferButtonLink
        className={clsx(className, styles.button, { [styles.withDiscount]: isDiscount })}
        {...data.link.pLink}
        {...rest}
      >
        {data.link.label}
      </OfferButtonLink>
    </DiscountButtonWrapper>
  )
}

type PanelButtonView = 'standard' | 'collapsed'

interface PanelButtonProps {
  onClick: () => void
  isShown: boolean
  withDiscount?: boolean
  isScrollable: boolean
  isPanelDiscount: boolean
  asPanel: ReactNode
}

const PanelButton: VFC<PanelButtonProps> = ({
  onClick,
  isShown,
  asPanel,
  isScrollable,
  isPanelDiscount,
}) => (
  <div className={styles.panelBox}>
    <div className={styles.stdBox}>{asPanel}</div>
    <button
      className={clsx(styles.arrow, {
        [styles.arrowOpened]: isShown,
        [styles.arrowScrollable]: isScrollable,
        [styles.arrowDiscount]: isPanelDiscount,
      })}
      type="button"
      onClick={onClick}
    >
      <ArrowBottomBird className={clsx(styles.arrowIcon, { [styles.iconOpened]: isShown })} />
    </button>
  </div>
)

type CollapsedView = 'full' | 'part' | 'none'

interface CollapsedButtonsProps {
  isShown: boolean
  data: readonly DSDealButton[]
  asButton: VFC<StandardDealButtonProps>
  isPanelDiscount: boolean
  isScrollable: boolean
}

const CollapsedButtons: VFC<CollapsedButtonsProps> = ({
  isShown,
  data,
  asButton: Btn,
  isPanelDiscount,
  isScrollable,
}) => {
  let view: CollapsedView = 'none'
  const forceScroll = isScrollable && isPanelDiscount

  if (isScrollable) {
    const count = data
      .slice(0, 2)
      .reduce<boolean[]>((prev, current) => {
        prev.push(getIsDiscount(current.price))
        return prev
      }, [])
      .filter(Boolean).length

    if (count === 2) {
      view = 'full'
    } else {
      view = count === 0 ? 'none' : 'part'
    }
  }

  const el = (
    <div
      className={clsx(styles.collapsed, {
        [styles.isCollapsedOpen]: isShown,
        [styles.isScrollable]: isScrollable,
        [styles.forceScroll]: forceScroll,
        [styles.viewFull]: isScrollable && view === 'full',
        [styles.viewPart]: isScrollable && view === 'part',
        [styles.viewNone]: isScrollable && view === 'none',
      })}
    >
      <Accordion className={styles.accordion} open={isShown}>
        <div className={styles.collapsedButtons}>
          {data.map(item => (
            <Btn key={item.link.pLink.href} data={item} />
          ))}
        </div>
      </Accordion>
    </div>
  )

  return isScrollable ? (
    <div className={styles.scrollWrapper}>
      {el}
      <div className={styles.helperGap} />
    </div>
  ) : (
    el
  )
}

interface AccordionDeal {
  className?: string
  view: 'bordered'
  withDiscount?: boolean
  data: readonly DSDealButton[]
  asButton: VFC<StandardDealButtonProps>
}

const AccordionDeal: VFC<AccordionDeal> = ({
  className,
  data,
  view,
  asButton: Btn,
  withDiscount = true,
}) => {
  const [isShown, setIsShown] = useState(false)
  const [panelData, ...collapsedData] = data
  const panelView: PanelButtonView = collapsedData.length ? 'collapsed' : 'standard'
  const isScrollable = collapsedData.length > 2
  const isPanelDiscount = getIsDiscount(panelData.price) && withDiscount

  const handleShown = useCallback((): void => setIsShown(pv => !pv), [])

  return (
    <div
      className={clsx(styles.container, className, {
        [styles.bordered]: view === 'bordered',
      })}
    >
      {panelView === 'standard' ? (
        <div className={styles.panelBox}>
          <Btn data={panelData} view={withDiscount ? 'discount' : 'btn'} />
        </div>
      ) : (
        <PanelButton
          onClick={handleShown}
          isScrollable={isScrollable}
          isShown={isShown}
          isPanelDiscount={isPanelDiscount}
          asPanel={<Btn data={panelData} view={withDiscount ? 'discount' : 'btn'} />}
        />
      )}

      <CollapsedButtons
        isPanelDiscount={isPanelDiscount}
        data={collapsedData}
        isShown={isShown}
        isScrollable={isScrollable}
        asButton={Btn}
      />
    </div>
  )
}

export default AccordionDeal
