import Linked from 'shared-components/service/Linked'
import clsx from 'clsx'
import styleUtils from 'shared-components/styles/util.module.css'
import ImageBlock from 'shared-components/ui/ImageBlock'
import { DSNCLinkedImage } from 'shared-definitions/types'
import styles from './NCLinkedImg.module.css'
import viewport from 'shared-definitions/viewport.json'

interface NCLinedImgProps {
  data: DSNCLinkedImage
  index: number
}

const NCLinedImg: React.VFC<NCLinedImgProps> = ({ data: { pLink, badges, image }, index }) => (
  <Linked className={clsx(styles.container, styleUtils.mobileFull)} {...pLink}>
    <ImageBlock
      cutLinks
      badges={badges}
      sizes={[{ bp: 'lg', val: viewport['lg'] }, '80vw']}
      image={{
        ...image,
        // cutting links because they are covered by another link
        credits: image.credits.map(i => ({
          title: i.title,
          pLink: null,
        })),
      }}
      preload={index === 0}
    />
  </Linked>
)

export default NCLinedImg
