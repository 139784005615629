import styles from './CreditLink.module.css'
import clsx from 'clsx'
import { BasicStyledComponent, DSCredit } from 'shared-definitions/types'
import Linked from 'shared-components/service/Linked'

interface CreditLinkProps extends BasicStyledComponent {
  credit: DSCredit
}

const CreditLink: React.VFC<CreditLinkProps> = ({ className, credit: { pLink, title } }) => {
  if (pLink) {
    return (
      <Linked {...pLink} className={clsx(styles.container, className)}>
        {title}
      </Linked>
    )
  }

  return <div className={clsx(styles.container, className)}>{title}</div>
}

export default CreditLink
