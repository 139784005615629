import styles from './PreviewLargePostLink.module.css'
import Linked from 'shared-components/service/Linked'
import clsx from 'clsx'
import AppImage from 'shared-components/ui/AppImage'
import { BasicStyledComponent, DSImage, LinkedLinkProps } from 'shared-definitions/types'

interface PostProps {
  title: string
  subtitle: string | null
  image: DSImage
}

export interface PreviewLargePostLinkProps extends BasicStyledComponent, LinkedLinkProps {
  post: PostProps
}

const PreviewLargePostLink: React.VFC<PreviewLargePostLinkProps> = ({
  href,
  target,
  post,
  className,
}) => (
  <Linked className={clsx(className, styles.container)} href={href} target={target}>
    <div className={styles.title}>{post.title}</div>
    <div className={styles.subtitle}>{post.subtitle}</div>
    {post.image ? (
      <AppImage
        sizes={[{ bp: 'md', val: 270 }, 90]}
        layout="raw"
        className={styles.imageContainer}
        image={post.image}
      />
    ) : (
      <div className={styles.imageContainer} />
    )}
  </Linked>
)

export default PreviewLargePostLink
