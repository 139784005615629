/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { DSStoreIcon } from 'shared-definitions/types'
import React from 'react'
import Apple from 'shared-svg/Apple.svg'
import Cart from 'shared-svg/Cart.svg'
import Google from 'shared-svg/PlayStore.svg'

const componentsMap: Record<DSStoreIcon, React.VFC> = {
  apple: Apple,
  google: Google,
}

interface StoreIconProps extends React.SVGAttributes<SVGElement> {
  icon: DSStoreIcon
}

const StoreIcon: React.VFC<StoreIconProps> = ({ icon, ...props }) => {
  const Component = componentsMap[icon]
  if (Component) {
    return <Component {...props} />
  }
  return <Cart {...props} />
}

export default StoreIcon
