import { getds } from 'ds'
import { useState, useCallback, useRef, useEffect } from 'react'
import { CommonDataContext } from 'shared-components/contexts/CommonDataContext'
import { NotificationsContext } from 'shared-components/contexts/NotificationsContext'
import { DSFacade } from 'types.aa.ds'

interface UseSubmitParams<T> {
  dataSource: DSFacade
  params: T
}

export interface UseSubmitProps<T> {
  submitting: boolean
  submit: (params: T) => Promise<boolean>
}

export function useSubmit<T>(
  handler: (v: UseSubmitParams<T>) => Promise<boolean>
): UseSubmitProps<T> {
  const {
    common: { strings },
  } = CommonDataContext.useContainer()
  const { enqueue } = NotificationsContext.useContainer()
  const [submitting, setSubmitting] = useState(false)
  const { ts } = CommonDataContext.useContainer()
  const storedHandler = useRef<typeof handler>()

  useEffect(() => {
    storedHandler.current = handler
  }, [handler])

  const submit = useCallback(
    async (params: T): Promise<boolean> => {
      if (!storedHandler.current) {
        enqueue({ msg: strings.errGeneral, duration: 3000, variant: 'error' })
        return false
      }

      setSubmitting(true)

      const success = await storedHandler.current({ dataSource: await getds({ ts }), params })

      setSubmitting(false)

      if (success) {
        enqueue({ msg: strings.msgSuccess, duration: 3000, variant: 'success' })
      } else {
        enqueue({ msg: strings.errGeneral, duration: 3000, variant: 'error' })
      }

      return success
    },
    [ts, strings, enqueue]
  )

  return { submit, submitting }
}
