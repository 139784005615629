import { DSNCQuote } from 'shared-definitions/types'
import clsx from 'clsx'
import styleContent from 'shared-components/styles/content.module.css'
import styleUtils from 'shared-components/styles/util.module.css'
import styles from './NCQuote.module.css'
import { htmlReactParse } from 'shared-code/html'

interface NCQuoteProps {
  data: DSNCQuote
}

const NCQuote: React.VFC<NCQuoteProps> = ({ data }) => (
  <div className={clsx(styleUtils.nc, styleContent.content)}>
    <q>
      {htmlReactParse(data.html)}
      {data.author ? <span className={styles.author}>{data.author}</span> : null}
    </q>
  </div>
)

export default NCQuote
