import { useEffect, useState, ChangeEvent, useRef, MutableRefObject, RefObject } from 'react'
import {
  VileModel,
  VileModelMeasure,
  VileModelResponse,
  DSAudioChartProps,
} from 'shared-definitions/types'
import { useInView } from 'react-intersection-observer'
import { useAutoFocus } from 'shared-components/hooks/use-auto-focus'
import { getVileModelMeasures, getVileModels } from '../function-services/audio-chart-api-calls'
interface NCAudioChartState {
  vileModels: VileModel[]
  filteredModels: VileModel[]
  isLoading: boolean
  selectedModel: {
    name: string | undefined
    id: number | undefined
  }
  filteredModelMeasures: VileModelMeasure[]

  selectedModelMeasure: VileModelMeasure | undefined
  scrollPosition: number | null
  dropdownOpen: boolean
  filterValue: string | undefined
  chartUrl: string | undefined
  dropdownRef: MutableRefObject<HTMLDivElement | null>
  dropdownFilterRef: RefObject<HTMLInputElement>
  vileModelMeasures: VileModelMeasure[]

  ref: (node?: Element | null | undefined) => void
  filterModels: (event: ChangeEvent<HTMLInputElement>) => void
  handleComparisonChange: (singleModel: VileModel) => Promise<void>
  handleModelMeasureChange: (singleMeasure: VileModelMeasure) => void
  updateLoadingState: (state: boolean) => void
  updateDropdownVisibility: (state: boolean) => void
  resetFilter: () => void
}

export function useAudioChart(chart: DSAudioChartProps): NCAudioChartState {
  const {
    style,
    modelID,
    measureID,
    comparisonModelID,
    comparisonMeasurementID,
    targetID,
    nudge,
    limitComparisonModels,
    allowedComparisonModelsIds,
    api_url,
  } = chart

  const [vileModels, setVileModels] = useState<VileModel[]>([])
  const [vileModelMeasures, setVileModelMeasures] = useState<VileModelMeasure[]>([])
  const [filteredModelMeasures, setFilteredModelMeasures] = useState<VileModelMeasure[]>([])
  const [filteredModels, setFilteredModels] = useState<VileModel[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [selectedModel, setSelectedModel] = useState<{
    name: string | undefined
    id: number | undefined
  }>({ name: undefined, id: undefined })
  const [selectedModelMeasure, setSelectedModelMeasure] = useState<VileModelMeasure>()

  const [chartUrl, setChartUrl] = useState<string>()
  const [scrollPosition, setScrollPosition] = useState<number | null>(null)
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
  const [filterValue, setFilterValue] = useState<string>('')
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const dropdownFilterRef = useAutoFocus<HTMLInputElement>(dropdownOpen)
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })

  const generateChartUrl = (comparisonModelId?: string, compMeasurementID?: string): void => {
    const baseRequest = new URL(`${api_url}vile/charts/${style}`)

    const params = new URLSearchParams()

    params.append('modelID', modelID)
    params.append('measureID', measureID)

    if (comparisonModelId) {
      params.append('comparisonModelID', String(comparisonModelId))
    }

    if (compMeasurementID) {
      params.append('comparisonMeasurementID', String(compMeasurementID))
    }

    if (targetID) {
      params.append('targetID', targetID)
    }
    if (nudge) {
      params.append('nudge', nudge)
    }

    baseRequest.search = params.toString()

    setChartUrl(baseRequest.toString())
  }

  const updateLoadingState = (state: boolean): void => {
    setIsLoading(state)
  }

  const updateDropdownVisibility = (visible: boolean): void => {
    setDropdownOpen(visible)
  }

  const resetFilter = (): void => {
    setFilteredModels(vileModels)
    setFilteredModelMeasures(vileModelMeasures)
    setFilterValue('')
  }

  const handleComparisonChange = async (singleModel: VileModel): Promise<void> => {
    setScrollPosition(window.scrollY)
    setDropdownOpen(false)
    setSelectedModel({
      id: singleModel.id,
      name: singleModel.product.name,
    })
    setIsLoading(true)

    const { measures } = await getVileModelMeasures(api_url, singleModel.id)

    if (measures.length && measures.length > 0) {
      generateChartUrl(String(singleModel.id), String(measures[0].id))
    }
  }
  const handleModelMeasureChange = (singleMeasure: VileModelMeasure): void => {
    setScrollPosition(window.scrollY)
    setDropdownOpen(false)
    setSelectedModelMeasure({
      id: singleMeasure.id,
      name: singleMeasure.name,
    })
    setIsLoading(true)
    generateChartUrl(String(modelID), String(singleMeasure.id))
  }

  const filterModels = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault()
    const newFilter: string = event.target.value.toLowerCase()
    const newFilteredModels = vileModels.filter(singleModel =>
      singleModel.product.name.toLowerCase().includes(newFilter)
    )
    const newFilteredModelMeasures = vileModelMeasures.filter(singleModelMeasure =>
      singleModelMeasure.name.toLowerCase().includes(newFilter)
    )

    setFilterValue(newFilter)
    setFilteredModels(newFilteredModels)
    setFilteredModelMeasures(newFilteredModelMeasures)
  }

  useEffect(() => {
    const fetchModels = async (): Promise<void> => {
      setIsLoading(true)

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const vileModelResponse: VileModelResponse = await getVileModels(api_url)

      const currentSelectedModel = vileModelResponse.models.find(
        singleModel => String(singleModel.id) === comparisonModelID
      )

      if (
        limitComparisonModels &&
        allowedComparisonModelsIds &&
        allowedComparisonModelsIds.length < 1
      ) {
        return
      }

      if (limitComparisonModels && comparisonModelID) {
        vileModelResponse.models = vileModelResponse.models.filter(
          singleModel =>
            allowedComparisonModelsIds?.includes(String(singleModel.id)) ||
            String(singleModel.id) === comparisonModelID
        )
      }

      if (currentSelectedModel) {
        setSelectedModel({
          id: currentSelectedModel?.id,
          name: currentSelectedModel?.product.name,
        })
      }
      setVileModels(vileModelResponse.models)
      setFilteredModels(vileModelResponse.models)
    }

    function handleClickOutside(event: MouseEvent): void {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false)
      }
    }

    const initiateModelMeasurements = async (modelId: number): Promise<void> => {
      setIsLoading(true)
      const { measures } = await getVileModelMeasures(api_url, modelId)
      if (measures.length && measures.length > 0) {
        setSelectedModelMeasure(measures[0])
      }
      setFilteredModelMeasures(measures)
      setVileModelMeasures(measures)
    }

    document.addEventListener('click', handleClickOutside)

    if (inView && !chartUrl) {
      void generateChartUrl(comparisonModelID, comparisonMeasurementID)
      if (style === 'comparison') {
        void fetchModels()
      }
      if (style === 'fr') {
        void initiateModelMeasurements(parseInt(modelID, 10))
      }
    }
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  return {
    vileModels,
    filteredModels,
    selectedModel,
    selectedModelMeasure,
    scrollPosition,
    dropdownOpen,
    chartUrl,
    filterValue,
    dropdownRef,
    dropdownFilterRef,
    isLoading,
    vileModelMeasures,
    filteredModelMeasures,
    ref,
    filterModels,
    handleComparisonChange,
    handleModelMeasureChange,
    updateLoadingState,
    resetFilter,
    updateDropdownVisibility,
  }
}
